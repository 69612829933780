import React, { Component } from 'react';
import PropTypes from 'prop-types';

class WelcomeIcons extends Component {
  render() {
    const {
      index
    } = this.props;

    switch(index) {
      case 1:
        return <img src={'/welcome_icon_1.png'} />;
      case 2:
        return <img src={'/welcome_icon_2.png'} />;
      case 3:
        return <img src={'/welcome_icon_3.png'} />;
    }  
  }
}

WelcomeIcons.propTypes = {
  index : PropTypes.number.isRequired
};


export default WelcomeIcons;
