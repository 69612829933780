import './PageNotFound.css';
import './Query.css';

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NotfoundImg from '../../components/Icons/NotFound';

export default class PageNotFound extends Component {
  render() {
    return (
      <div>
        <div id="main">
          <h1>
            <NotfoundImg />
          </h1>
          <p>
            A good wander can unveil a wonder city. But this one didn&apos;t!
          </p>
          <p>
            Head over to our <Link to="/">home page</Link> to find what
            you&apos;re looking for.
          </p>
        </div>
      </div>
    );
  }
}
