
import { combineReducers } from 'redux';
import { employees } from './employees';
import { selectItems } from './selectItems';
import { heatingFuelsUnits } from './heatingFuelsUnits';
import { track } from './track';
import { settings } from './settings';
import { dashboard } from './dashboard';

export const reducers = combineReducers({
  employees,
  selectItems,
  heatingFuelsUnits,
  track,
  settings,
  dashboard
});
