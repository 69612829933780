import {createStore, applyMiddleware , compose} from 'redux';
import thunk from 'redux-thunk';
/*import logger from 'redux-logger';*/
import { reducers } from '../reducers/index';
/*import promise from 'redux-promise-middleware';*/

export const store = createStore(
  reducers,
  compose(
    applyMiddleware(thunk)
  )
);
