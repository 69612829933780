const VerifierSettings = function(type,organizationSub,today,employees) {
  if(type=='emp')
  {
    if(organizationSub.start_date =='')
      return {res:false,msj:'Start date is required'};
    if(organizationSub.start_date >= today)
      return {res:false,msj:'Start date must be lower than date of today'};
    if(!Number.isInteger(parseFloat(organizationSub.total_workdays)) || parseFloat(organizationSub.total_workdays)<0 || parseFloat(organizationSub.total_workdays)> 365)
      return {res:false,msj:'Total workdays is required and must be an integer between 0 and 365'};
    if(organizationSub.no_longer_working)
    {
      if(organizationSub.end_date =='')
        return {res:false,msj:'Employement end date is required'};
      if(organizationSub.end_date >= today)
        return {res:false,msj:'End date must be lower than date of today'};
      if(organizationSub.start_date > organizationSub.end_date)
        return {res:false,msj:'Employement end date must be greater than start date'};
    }
  }
  if(type=='pref')
  {
    if(employees.languages_id =='')
      return {res:false,msj:'Languages is required'};
    if(employees.date_format_id =='')
      return {res:false,msj:'Date format is required'};
    if(employees.units_id =='')
      return {res:false,msj:'Units is required'};
    
  }

  return {res:true,msj:''}; 
};
  
export default VerifierSettings;
