export const VerifierSchedule = function(schedule/*,travel,commute,space,heatingFuelsLength,workspace,digital,purchases*/) {

  if(schedule.setup_name =='')
    return {res:false,msj:'Schedule : Steup name is required'};
  if(schedule.work_mode_id =='')
    return {res:false,msj:'Schedule : Work mode is required'};
  if(schedule.from_date =='')
    return {res:false,msj:'Schedule : From date is required'};
  if(schedule.to_date =='')
    return {res:false,msj:'Schedule : To date is required'};
  if(schedule.from_date > schedule.to_date)
    return {res:false,msj:'Schedule : To date must be greater than From date'};
  if(!Number.isInteger(parseFloat(schedule.nb_workdays)) || parseFloat(schedule.nb_workdays)<0 || parseFloat(schedule.nb_workdays)> 365)
    return {res:false,msj:'Schedule : Number of workdays is required and must be an integer between 0 and 365'};
  return {res:true,msj:''};
};

export const VerifierTravel = function(travel){
  if(travel.apply)
  {
    if(travel.travel_mode_id =='')
      return {res:false,msj:'Travel : Travel mode is required'};
    if(travel.travel_mode_id == 1)
    {
      if(travel.airports_from_id == '')
        return {res:false,msj:'Travel : Travel from is required'};
      if(travel.airports_to_id == '')
        return {res:false,msj:'Travel : Travel to is required'};
    }
    else
    {
      if(travel.distance == '' || parseFloat(travel.distance)<0)
        return {res:false,msj:'Travel : distance is required and must be greater than 0'};
      if(travel.distance_units_id =='')
        return {res:false,msj:'Travel : unit of distance is required'};
    }
    if(travel.travel_class_id =='')
      return {res:false,msj:'Travel : Travel class is required'};
  }
  return {res:true,msj:''};
};

export const VerifierCommute = function(commute){
  if(commute.apply)
  {
    if(commute.commute_mode_id =='')
      return {res:false,msj:'Commute : Commute mode is required'};
    if(commute.total_distance == '' || parseFloat(commute.total_distance)<0)
      return {res:false,msj:'Commute : Total distance is required and must be greater than 0'};
    if(commute.distance_units_id =='')
      return {res:false,msj:'Commute : unit of total distance is required'};
  }
  return {res:true,msj:''};
};

export const VerifierSpace_1 = function(space){
  if(space.location_id =='')
    return {res:false,msj:'Space : Location is required'};
  if(space.floor_area == '' || parseFloat(space.floor_area)<0)
    return {res:false,msj:'Space : Floor area is required and must be greater than 0'};
  if(space.floor_area_unit_id =='')
    return {res:false,msj:'Space : unit of floor area is required'};
  if(space.home_type_id =='')
    return {res:false,msj:'Space : Building type is required'};
  if(!Number.isInteger(parseFloat(space.nb_occupants)) || parseFloat(space.nb_occupants)<0)
    return {res:false,msj:'Space : Nb of occupants is required and must be an integer greater than 0'};
  
  return {res:true,msj:''};
};

export const VerifierSpace_2 = function(space){
  if(space.heating_fuel_id =='')
    return {res:false,msj:'Space : Main heating fuel is required'};
  if(space.temperature_control_id =='')
    return {res:false,msj:'Space : Temperature control is required'};
  if(space.air_conditionning_id =='')
    return {res:false,msj:'Space : Air conditionning is required'};
  if(space.renewable_electricity_id =='')
    return {res:false,msj:'Space : Renewable electricity is required'};
  return {res:true,msj:''};
};

export const VerifierSpace_3 = function(space,heatingFuelsLength){
  if(space.energy_data_input_type == 'use my data')
  {
    if(space.annual_electricity == '' || parseFloat(space.annual_electricity)<0)
      return {res:false,msj:'Space : Annual electricity is required and must be greater than 0'};
    if(space.annual_electricity_unit_id =='')
      return {res:false,msj:'Space : Unit of annual electricity is required'};
    if(heatingFuelsLength !=0)
    {
      if(space.annual_heat == '' || parseFloat(space.annual_heat)<0)
        return {res:false,msj:'Space : Annual heating fuel is required and must be greater than 0'};
      if(space.annual_heat_unit_id =='')
        return {res:false,msj:'Space : Unit of annual heating fuel is required'};
    }
  }
  return {res:true,msj:''};
};

export const VerifierWorkspace_1 = function(workspace){
  if(workspace.apply)
  {
    if(workspace.workspace_area == '' || parseFloat(workspace.workspace_area)<0)
      return {res:false,msj:'Workspace : Floor area is required and must be greater than 0'};
    if(workspace.workspace_area_unit_id =='')
      return {res:false,msj:'Workspace : unit of floor area is required'};
    if(!Number.isInteger(parseFloat(workspace.nb_people_sharing)) || parseFloat(workspace.nb_people_sharing)<0)
      return {res:false,msj:'Workspace : Nb of people sharing is required and must be an integer greater than 0'};
  }
  return {res:true,msj:''};
};
export const VerifierWorkspace_2 = function(workspace){
  if(workspace.apply)
  {
    if(workspace.daylight_work_percent == '' || parseFloat(workspace.daylight_work_percent)<0 || parseFloat(workspace.daylight_work_percent)>100)
      return {res:false,msj:'Workspace : Working in daylight is required and must be between 0 and 100'};
    if(workspace.ambient_lighting_id =='')
      return {res:false,msj:'Workspace : Ambient lighting is required'};
    if(workspace.desk_lighting_id =='')
      return {res:false,msj:'Workspace : Desk lighting is required'};
  }
  return {res:true,msj:''};
};
export const VerifierDigital_1 = function(digital){
  if(digital.apply)
  {
    if(digital.main_internet_id =='')
      return {res:false,msj:'Digital : Main internet connexion is required'};
    if(digital.main_device_id =='')
      return {res:false,msj:'Digital : Main device is required'};
  }
  return {res:true,msj:''};
};

export const VerifierDigital_2 = function(digital){
  if(digital.apply)
  {
    if(digital.percent_video_calling == '' || parseFloat(digital.percent_video_calling)<0 || parseFloat(digital.percent_video_calling)>100)
      return {res:false,msj:'Digital : Video calling usage rate is required and must be between 0 and 100'};
  }
  return {res:true,msj:''};
};

export const VerifierPurchases_1 = function(purchases){
  if(purchases.apply)
  {
    if(!Number.isInteger(parseFloat(purchases.regular_desk)) || parseFloat(purchases.regular_desk)<0)
      return {res:false,msj:'Purchases : Regular desk is required and must be an integer greater than 0'};
    if(!Number.isInteger(parseFloat(purchases.small_desk)) || parseFloat(purchases.small_desk)<0)
      return {res:false,msj:'Purchases : Small desk is required and must be an integer greater than 0'};
    if(!Number.isInteger(parseFloat(purchases.bookcase)) || parseFloat(purchases.bookcase)<0)
      return {res:false,msj:'Purchases : Bookcase is required and must be an integer greater than 0'};
    if(!Number.isInteger(parseFloat(purchases.cabinet)) || parseFloat(purchases.cabinet)<0)
      return {res:false,msj:'Purchases : Cabinet is required and must be an integer greater than 0'};
    if(!Number.isInteger(parseFloat(purchases.task_chair)) || parseFloat(purchases.task_chair)<0)
      return {res:false,msj:'Purchases : Task chair is required and must be an integer greater than 0'};
    if(!Number.isInteger(parseFloat(purchases.visitor_chair)) || parseFloat(purchases.visitor_chair)<0)
      return {res:false,msj:'Purchases : Visitor chair is required and must be an integer greater than 0'};
    if(!Number.isInteger(parseFloat(purchases.arm_chair)) || parseFloat(purchases.arm_chair)<0)
      return {res:false,msj:'Purchases : Arm chair is required and must be an integer greater than 0'};
    if(!Number.isInteger(parseFloat(purchases.sofa)) || parseFloat(purchases.sofa)<0)
      return {res:false,msj:'Purchases : Sofa is required and must be an integer greater than 0'};
  }
  return {res:true,msj:''};
};

export const VerifierPurchases_2 = function(purchases){
  if(purchases.apply)
  {
    if(!Number.isInteger(parseFloat(purchases.smartphone)) || parseFloat(purchases.smartphone)<0)
      return {res:false,msj:'Purchases : Smartphone is required and must be an integer greater than 0'};
    if(!Number.isInteger(parseFloat(purchases.tablet)) || parseFloat(purchases.tablet)<0)
      return {res:false,msj:'Purchases : Tablet is required and must be an integer greater than 0'};
    if(!Number.isInteger(parseFloat(purchases.laptop)) || parseFloat(purchases.laptop)<0)
      return {res:false,msj:'Purchases : Laptop is required and must be an integer greater than 0'};
    if(!Number.isInteger(parseFloat(purchases.desktop)) || parseFloat(purchases.desktop)<0)
      return {res:false,msj:'Purchases : Desktop is required and must be an integer greater than 0'};
    if(!Number.isInteger(parseFloat(purchases.monitor)) || parseFloat(purchases.monitor)<0)
      return {res:false,msj:'Purchases : Monitor is required and must be an integer greater than 0'};
    if(!Number.isInteger(parseFloat(purchases.microphone)) || parseFloat(purchases.microphone)<0)
      return {res:false,msj:'Purchases : Microphone is required and must be an integer greater than 0'};
    if(!Number.isInteger(parseFloat(purchases.ext_camera)) || parseFloat(purchases.ext_camera)<0)
      return {res:false,msj:'Purchases : Ext.camera is required and must be an integer greater than 0'};
    if(!Number.isInteger(parseFloat(purchases.ext_sound_system)) || parseFloat(purchases.ext_sound_system)<0)
      return {res:false,msj:'Purchases : Ext.sound is required and must be an integer greater than 0'};
  }
  return {res:true,msj:''};
};




