import api from '../api/selectItems';
export const ACTION_TYPES={
  FETCH_ALL_SELECT_ITEMS : 'FETCH_ALL_SELECT_ITEMS'
};

export const fetchAllSelectItems = () => dispatch => {
  api.selectItems().fetchAllSelectItems()
    .then(res => {
      dispatch({ 
        type: ACTION_TYPES.FETCH_ALL_SELECT_ITEMS,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};





