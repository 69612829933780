import './TrackForm.css';
import React,{useState,useEffect}  from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from '../../actions/employees';
import * as selectItems_actions from '../../actions/selectItems';
import * as heatingFuelsUnits_actions from '../../actions/heatingFuelsUnits';
import * as track_actions from '../../actions/track';
import {toast} from 'react-toastify';
import * as Req from './TrackRequired';
import CarteMap from '../../components/Icons/CarteMap';

const TrackForm = (props)=> {
  const {
    index
  } = props;

  var intialSchedule ={
    id:0,
    setup_name : '',
    work_mode_id:'',
    from_date:'',
    to_date:'',
    nb_workdays: '0'
  };
  var intialTravel={
    id:0,
    apply: true,
    travel_mode_id:'',
    airports_from_id:'',
    airports_to_id : '',
    travel_class_id:'',
    distance:'0',
    distance_units_id:''

  };

  
  var initialCommute={
    id:0,
    apply:true,
    total_distance:'0',
    distance_units_id:'',
    commute_mode_id:''
    
  };

  var initialSpace = {
    id:0,
    location_id : '',
    floor_area : '0',
    floor_area_unit_id :'',
    home_type_id : '',
    nb_occupants : '0',
    heating_fuel_id :'',
    temperature_control_id:'',
    air_conditionning_id : '',
    renewable_electricity_id:'',
    energy_data_input_type:'use stats',
    annual_electricity:'0',
    annual_electricity_unit_id:'',
    annual_heat:'0',
    annual_heat_unit_id:''
  };
  var initialWorkspace={
    id:0,
    apply : true,
    sharing : true,
    workspace_area:'0',
    workspace_area_unit_id:'',
    nb_people_sharing:'0',
    daylight_work_percent:'0',
    desk_lighting_id:'',
    ambient_lighting_id:'',
    video_call_lighting:false,
    plugged_smart_strip:false

  };
  var initialDigital={
    id:0,
    apply: true,
    hrs_daily_online_calling : '0',
    percent_video_calling:'0',
    main_internet_id:'',
    main_device_id:'',
    monitor:false,
    microphone:false,
    ext_camera:false,
    ext_sound:false,
    zoom:'0',
    google:'0',
    teams:'0',
    slack:'0',
    other:'0'
  };
  var initialPurchases={
    id:'0',
    apply:true,
    regular_desk:'0',
    small_desk :'0', 
    bookcase :'0', 
    cabinet :'0', 
    task_chair :'0',
    visitor_chair :'0', 
    arm_chair :'0',
    sofa :'0', 
    smartphone :'0', 
    tablet :'0', 
    laptop :'0', 
    desktop :'0',
    monitor :'0', 
    microphone :'0', 
    ext_camera :'0', 
    ext_sound_system:'0'
  };

  var [schedule,SetSchedule]=useState(intialSchedule);
  var [travel,SetTravel]=useState(intialTravel);
  var [commute,SetCommute]=useState(initialCommute);
  var [space,SetSpace]=useState(initialSpace);
  var [workspace,SetWorkspace]=useState(initialWorkspace);
  var [digital,SetDigital]=useState(initialDigital);
  var [purchases,SetPurchases]=useState(initialPurchases);


  useEffect(() => {
    props.userConnected();
    props.fetchAllSelectItems();
    props.trackById(index.id);
    SetSchedule(index.id ==0 || props.trackItem.length == 0 ? intialSchedule : props.trackItem[0].schedule);
    SetTravel(index.id ==0 || props.trackItem.length == 0 ? intialTravel : props.trackItem[0].travel);
    SetCommute(index.id ==0 || props.trackItem.length == 0 ? initialCommute : props.trackItem[0].commute);
    SetSpace(index.id ==0 || props.trackItem.length == 0 ? initialSpace : props.trackItem[0].space);
    SetWorkspace(index.id ==0 || props.trackItem.length == 0 ? initialWorkspace : props.trackItem[0].workspace);
    SetDigital(index.id ==0 || props.trackItem.length == 0 ? initialDigital : props.trackItem[0].digital);
    SetPurchases(index.id ==0 || props.trackItem.length == 0 ? initialPurchases : props.trackItem[0].purchases);
    
    if(space.heating_fuel_id!='')
      props.fetchHeatingFuelsUnits(space.heating_fuel_id);
    
  }, [index.id,props.trackItem.length]);
  

  toast.configure();

  var year = '';

  if(props.user[0]!= null)
  {
    year = props.user[0].year;
  }

  if(props.selectItemsList.length == 0 || (index.id!=0 && props.trackItem.length == 0))
  {
    return <div className="loader_blue"></div>;
  }
  else
  { 
    switch(index.count) {
      case 1:
        return<div>
          <div className="track_form_title">
            <span>SCHEDULE</span>
          </div>
          <label className="form_label label1">SETUP NAME</label>
          <input id="setup_name_input" className="form_input" type="text" maxLength="25"  required value={schedule.setup_name} 
            onChange={(e)=>{SetSchedule({...schedule , setup_name:e.target.value});}}/>
          <label className="form_label label2">WORK MODE</label>
          <select className="form_select" id="work_mode_select" required value={schedule.work_mode_id} 
            onChange={(e)=>{SetSchedule({...schedule , work_mode_id:e.target.value});}}>
            <option value=""></option>
            {props.selectItemsList[0].workModeList.map((workMode)=>{
              return <option key={workMode.id} value={workMode.id}>{workMode.name}</option>;
            })}
          </select>
          <label className="form_label label3">FROM / TO</label>
          <input id="schedule_from_input" className="form_input" type="date"  required value={schedule.from_date}
            onChange={(e)=>{SetSchedule({...schedule , from_date: e.target.value});}}/>
          <input id="schedule_to_input" className="form_input" type="date"  required value={schedule.to_date} 
            onChange={(e)=>{SetSchedule({...schedule , to_date:e.target.value});}}/>
          <label className="form_label label4">NUMBER OF<br/>WORKDAYS</label>
          <input id="nb_workdays_input" className="form_input" type="number" min="0" max="365" step="1"  required value={schedule.nb_workdays} 
            onChange={(e)=>{SetSchedule({...schedule , nb_workdays: e.target.value});}}/>
        </div>; 
      case 2:
        var response_schedule = Req.VerifierSchedule(schedule);
        if(!response_schedule.res)
        {
          toast.error(response_schedule.msj,{position:toast.POSITION.TOP_CENTER});
          document.getElementById('chek_error').click();
          return <div className="loader_blue"></div>;
        }
        else
        {
          return<div>
            <div className="track_form_title">
              <span>TRAVEL</span>
            </div>
            <label className="form_label not_app_label">Not Applicable</label>
            <input type="checkbox" className="form_chekbox" id="travel_not_app" checked={!travel.apply?'checked':''} 
              onClick={()=>{
                SetTravel({...travel,apply:!travel.apply,travel_mode_id:'',airports_from_id:'',airports_to_id:''
                  ,travel_class_id:'', distance:'0', distance_units_id:''});
              }}
            />
            <div style={{pointerEvents: !travel.apply?'none':'auto'}}>
              <label className="form_label label1">TRAVEL MODE</label>
              <select className="form_select" id="travel_mode_select" required value={travel.travel_mode_id} 
                onChange={(e)=>{
                  SetTravel({...travel , travel_mode_id: e.target.value, airports_from_id:''
                    ,airports_to_id:'',distance:'0',distance_units_id:''});
                  if(e.target.value != 1)
                    toast.info('You can use Google Maps to calculate distance',{position:toast.POSITION.TOP_CENTER});
                }}>
                <option value=""></option>
                {props.selectItemsList[0].travelModeList.map((travelMode)=>{
                  return <option key={travelMode.id} value={travelMode.id}>{travelMode.name}</option>;
                })}</select>

              {/******************* airports ***************************/}
              <div style={{display:travel.travel_mode_id==1?'block':'none'}}>
                <label className="form_label label2">FROM</label>
                <select className="form_select" id="travel_from_airports_select" value={travel.airports_from_id}
                  onChange={(e)=>{SetTravel({...travel,airports_from_id:e.target.value});}} >
                  <option value=""></option>
                  {props.selectItemsList[0].airportsList.map((airports)=>{
                    return <option key={airports.id} value={airports.id}>{airports.name +' ('+airports.code_IATA+')'}</option>;
                  })}
                </select>
                <label className="form_label label3">TO</label>
                <select className="form_select" id="travel_to_airports_select" value={travel.airports_to_id}
                  onChange={(e)=>{SetTravel({...travel,airports_to_id:e.target.value});}}>
                  <option value=""></option>
                  {props.selectItemsList[0].airportsList.map((airports)=>{
                    return <option key={airports.id} value={airports.id}>{airports.name +' ('+airports.code_IATA+')'}</option>;
                  })}
                </select>
              </div>
              {/******************* others ********************************/} 
              <div style={{display:travel.travel_mode_id==1?'none':'block'}}>
                <label className="form_label label2">DISTANCE</label>
                <input id="travel_distance_input" className="form_input" type="number" min="0" step="0.01" value={travel.distance} 
                  onChange={(e)=>{SetTravel({...travel,distance:e.target.value});}}/>
                <select className="form_select" id="travel_distance_units_select" value={travel.distance_units_id} 
                  onChange={(e)=>{SetTravel({...travel,distance_units_id: e.target.value});}}>
                  <option value=""></option>
                  {props.selectItemsList[0].distanceUnitsList.map((distUnits)=>{
                    return <option key={distUnits.id} value={distUnits.id}>{distUnits.name}</option>;
                  })}
                </select>
                <label className="form_label label3">GOOGLE MAP</label>
                <a className="use_google_map" onClick={function(){
                  window.open('https://www.google.com/maps', '_blank').focus();
                }}><CarteMap/></a>
              </div>
                            
              <label className="form_label label4">TRAVEL CLASS</label>
              <select className="form_select" id="travel_class_select" value={travel.travel_class_id} 
                onChange={(e)=>{SetTravel({...travel , travel_class_id: e.target.value});}}>
                <option value=""></option>
                {props.selectItemsList[0].travelClassList.map((travelclass)=>{
                  return <option key={travelclass.id} value={travelclass.id}>{travelclass.name}</option>;
                })}
              </select>
            </div>
          </div>;
        }
      case 3:
        var response_travel = Req.VerifierTravel(travel);
        if(!response_travel.res)
        {
          toast.error(response_travel.msj,{position:toast.POSITION.TOP_CENTER});
          document.getElementById('chek_error').click();
          return <div className="loader_blue"></div>;
        }
        else
        {
          return<div>
            <div className="track_form_title">
              <span>COMMUTE</span>
            </div>
            <label className="form_label not_app_label">Not Applicable</label>
            <input type="checkbox" className="form_chekbox" id="commute_not_app" checked={!commute.apply?'checked':''} 
              onClick={()=>{
                SetCommute({...commute,apply:!commute.apply,total_distance:0,distance_units_id:'',commute_mode_id:''});
              }}
            />
            <div style={{pointerEvents: !commute.apply?'none':'auto'}}>
              <label className="form_label label1">COMMUTE MODE</label>
              <select className="form_select" id="commute_mode_select" value={commute.commute_mode_id} 
                onChange={(e)=>{
                  SetCommute({...commute,commute_mode_id:e.target.value});
                  toast.info('You can use Google Maps to calculate distance',{position:toast.POSITION.TOP_CENTER});
                }}>
                <option value=""></option>
                {props.selectItemsList[0].commuteModeList.map((commuteMode)=>{
                  return <option key={commuteMode.id} value={commuteMode.id}>{commuteMode.name}</option>;
                })}</select>
              <label className="form_label label2">DISTANCE</label>
              <input id="commute_total_input" className="form_input" type="number" min="0" step="0.01"  required value={commute.total_distance} 
                onChange={(e)=>{SetCommute({...commute,total_distance:e.target.value});}}/>
              <select className="form_select" id="commute_total_select"required value={commute.distance_units_id} 
                onChange={(e)=>{SetCommute({...commute,distance_units_id: e.target.value});}}>
                <option value=""></option>
                {props.selectItemsList[0].distanceUnitsList.map((distUnits)=>{
                  return <option key={distUnits.id} value={distUnits.id}>{distUnits.name}</option>;
                })}</select>
              <label className="form_label label3">GOOGLE MAP</label>
              <a className="use_google_map" onClick={function(){
                window.open('https://www.google.com/maps', '_blank').focus();
              }}><CarteMap/></a>
            </div>
          </div>;
        }
      case 4:
        var response_commute = Req.VerifierCommute(commute);
        if(!response_commute.res)
        {
          toast.error(response_commute.msj,{position:toast.POSITION.TOP_CENTER});
          document.getElementById('chek_error').click();
          return <div className="loader_blue"></div>;
        }
        else
        {
          return<div>
            <div className="track_form_title">
              <span>HOME</span>
            </div>
            <div>
              <label className="form_label label1">LOCATION</label>
              <select className="form_select" id="location_select" required value={space.location_id} 
                onChange={(e)=>{SetSpace({...space,location_id: e.target.value});}}>
                <option value=""></option>
                {props.selectItemsList[0].countryList.map((country)=>{
                  return <option key={country.id} value={country.id}>{country.location}</option>;
                })}
              </select>
              <label className="form_label label2" >FLOOR AREA</label>
              <input id="floor_area_home_input" className="form_input" type="number" required  min="0" step="0.1" value={space.floor_area} 
                onChange={(e)=>{SetSpace({...space,floor_area: e.target.value});}}/>
              <select className="form_select" id="floor_area_home_select" required value={space.floor_area_unit_id} 
                onChange={(e)=>{SetSpace({...space,floor_area_unit_id: e.target.value});}}>
                <option value=""></option>
                {props.selectItemsList[0].areaUnitsList.map((area)=>{
                  return <option key={area.id} value={area.id}>{area.name}</option>;
                })}
              </select>
              <label className="form_label label3">BUILDING TYPE</label>
              <select className="form_select" id="home_type_select" required value={space.home_type_id} 
                onChange={(e)=>{SetSpace({...space,home_type_id: e.target.value});}}>
                <option value=""></option>
                {props.selectItemsList[0].homeTypeList.map((homeType)=>{
                  return <option key={homeType.id} value={homeType.id}>{homeType.name}</option>;
                })}
              </select>
              <label className="form_label label4">NB OF <br/>OCCUPANTS</label>
              <input className="form_input" type="number"  min="0" step="1" id="nb_occupants" required value={space.nb_occupants}
                onChange={(e)=>{SetSpace({...space,nb_occupants: e.target.value});}}/>
              <label className="form_label_second label_second4">*including you</label>
            </div>
          </div>;
        }  
      case 5:
        var response_space_1 = Req.VerifierSpace_1(space);
        if(!response_space_1.res)
        {
          toast.error(response_space_1.msj,{position:toast.POSITION.TOP_CENTER});
          document.getElementById('chek_error').click();
          return <div className="loader_blue"></div>;
        }
        else
        {
          return<div>
            <div className="track_form_title">
              <span>HOME</span>
            </div>
            <label className="form_label label1">MAIN <br/> HEATING FUEL</label>
            <select className="form_select" id="heating_fuel_select" required value={space.heating_fuel_id} onChange={(e)=>{
              SetSpace({...space,heating_fuel_id: e.target.value});
              if(e.target.value =='')
                props.fetchHeatingFuelsUnits(0);
              else
                props.fetchHeatingFuelsUnits(e.target.value);
              SetSpace({...space,heating_fuel_id: e.target.value,annual_heat:0, annual_heat_unit_id: ''});
            }}>
              <option value=""></option>
              {props.selectItemsList[0].heatingFuelsList.map((heatingFuel)=>{
                return <option key={heatingFuel.id} value={heatingFuel.id}>{heatingFuel.name}</option>;
              })}
            </select>
            <label className="form_label label2">TEMPERATURE<br/>CONTROL</label>
            <select className="form_select" id="temperature_control_select" required value={space.temperature_control_id} 
              onChange={(e)=>{SetSpace({...space,temperature_control_id: e.target.value});}}>
              <option value=""></option>
              {props.selectItemsList[0].temperatureControlList.map((temperature)=>{
                return <option key={temperature.id} value={temperature.id}>{temperature.name}</option>;
              })}
            </select>
            <label className="form_label label3">AIR<br/>CONDITIONNING</label>
            <select className="form_select" id="air_conditioning_select" required value={space.air_conditionning_id} 
              onChange={(e)=>{SetSpace({...space,air_conditionning_id: e.target.value});}}>
              <option value=""></option>
              {props.selectItemsList[0].airConditioningList.map((air)=>{
                return <option key={air.id} value={air.id}>{air.name}</option>;
              })}
            </select>
            <label className="form_label label4">RENEWABLE<br/>ELECTRICITY</label>
            <select className="form_select" id="renewable_electricity_select" required value={space.renewable_electricity_id} 
              onChange={(e)=>{SetSpace({...space,renewable_electricity_id: e.target.value});}}>
              <option value=""></option>
              {props.selectItemsList[0].renewableElectricityList.map((elect)=>{
                return <option key={elect.id} value={elect.id}>{elect.name}</option>;
              })}
            </select>
          </div>;
        }
      case 6:
        var response_space_2 = Req.VerifierSpace_2(space);
        if(!response_space_2.res)
        {
          toast.error(response_space_2.msj,{position:toast.POSITION.TOP_CENTER});
          document.getElementById('chek_error').click();
          return <div className="loader_blue"></div>;
        }
        else
        {
          if(props.heatingFuelsUnitsList.length !=0)
          {
            return<div>
              <div className="track_form_title">
                <span>HOME</span>
              </div>
              <div className="form_text">
                We can estimate space-related energy usage (and carbon emissions) 
                based on statistical data but it would be more accurate if we use 
                real consumption data. Do you have access to this building’s 
                electricity and heating usage data?
              </div>
              <a id="use_stats_button" className={space.energy_data_input_type == 'use stats' ? 'form_button active':'form_button inactive'} href="#" onClick={function () {
                SetSpace({...space,energy_data_input_type:'use stats',annual_electricity:0,annual_electricity_unit_id:'',
                  annual_heat:0,annual_heat_unit_id:''});
              }}>Use statistics</a>
              <a id="use_my_data_button" className={space.energy_data_input_type == 'use stats' ? 'form_button inactive':'form_button active'} href="#" onClick={function () {
                SetSpace({...space,energy_data_input_type:'use my data'});
              }}>Use my data</a>
              <div style={{display:space.energy_data_input_type=='use stats'?'none':'block'}}>
                <label className="form_label label3">{year} ANNUAL<br/>ELECTRICITY</label>
                <input id="annual_electricity_input" className="form_input" type="number" required  min="0" step="0.0001"  value={space.annual_electricity} 
                  onChange={(e)=>{SetSpace({...space,annual_electricity: e.target.value});}}/>
                <select className="form_select" id="electricity_units_select" required value={space.annual_electricity_unit_id} 
                  onChange={(e)=>{SetSpace({...space,annual_electricity_unit_id: e.target.value});}}>
                  <option value=""></option>
                  {props.selectItemsList[0].electricityUnitsList.map((electUnits)=>{
                    return <option key={electUnits.id} value={electUnits.id}>{electUnits.name}</option>;
                  })}
                </select>
                <label className="form_label label4">{year} ANNUAL<br/>HEATING FUEL</label>
                <input id="heating_fuel_input" className="form_input" type="number"  min="0" step="0.0001" required value={space.annual_heat} 
                  onChange={(e)=>{SetSpace({...space,annual_heat: e.target.value});}}/>
                <select className="form_select" id="heating_fuels_units_select" required value={space.annual_heat_unit_id} 
                  onChange={(e)=>{SetSpace({...space,annual_heat_unit_id: e.target.value});}}>
                  <option value=""></option>
                  {props.heatingFuelsUnitsList.map((heatUnits)=>{
                    return <option key={heatUnits.id} value={heatUnits.id}>{heatUnits.name}</option>;
                  })}
                </select>
              </div>
            </div>;
          }
          else{
            return<div>
              <div className="track_form_title">
                <span>HOME</span>
              </div>
              <div className="form_text">
                We can estimate your home carbon 
                emissions based on statistical data but it would be more 
                accurate if we would use real consumption data. Are you willing to 
                share your electricity and heating usage with us?
              </div>
              <a id="use_stats_button" className={space.energy_data_input_type == 'use stats' ? 'form_button active':'form_button inactive'} href="#" onClick={function () {
                SetSpace({...space,energy_data_input_type:'use stats',annual_electricity:0,annual_electricity_unit_id:'',
                  annual_heat:0,annual_heat_unit_id:''});
              }}>Use statistics</a>
              <a id="use_my_data_button" className={space.energy_data_input_type == 'use stats' ? 'form_button inactive':'form_button active'} href="#" onClick={function () {
                SetSpace({...space,energy_data_input_type:'use my data'});
              }}>Use my data</a>
              <div style={{display:space.energy_data_input_type=='use stats'?'none':'block'}}>
                <label className="form_label label3">{year} ANNUAL<br/>ELECTRICITY</label>
                <input id="annual_electricity_input" className="form_input" type="number" required  min="0" step="0.0001"  value={space.annual_electricity} 
                  onChange={(e)=>{SetSpace({...space,annual_electricity: e.target.value});}}/>
                <select className="form_select" id="electricity_units_select" required value={space.annual_electricity_unit_id} 
                  onChange={(e)=>{SetSpace({...space,annual_electricity_unit_id: e.target.value});}}>
                  <option value=""></option>
                  {props.selectItemsList[0].electricityUnitsList.map((electUnits)=>{
                    return <option key={electUnits.id} value={electUnits.id}>{electUnits.name}</option>;
                  })}
                </select>
              </div>
            </div>;
          }
        }
      case 7:
        var response_space_3 = Req.VerifierSpace_3(space,props.heatingFuelsUnitsList.length);
        if(!response_space_3.res)
        {
          toast.error(response_space_3.msj,{position:toast.POSITION.TOP_CENTER});
          document.getElementById('chek_error').click();
          return <div className="loader_blue"></div>;
        }
        else
        {
          return<div>
            <div className="track_form_title">
              <span>WORKSPACE</span>
            </div> 
            <label className="form_label not_app_label">Not Applicable</label>
            <input type="checkbox" className="form_chekbox" id="workspace_not_app" checked={!workspace.apply?'checked':''} 
              onClick={()=>{
                SetWorkspace({...workspace,apply:!workspace.apply,sharing:true,workspace_area:0,workspace_area_unit_id:'',nb_people_sharing:0,daylight_work_percent:0,
                  desk_lighting_id:'',ambient_lighting_id:'',video_call_lighting:false,plugged_smart_strip:false});
              }}
            />
            <div style={{pointerEvents: !workspace.apply?'none':'auto'}}>
              <label className="form_label label1">FLOOR AREA</label>
              <input id="floor_area_workspace_input" className="form_input" type="number"  min="0" step="0.1" required value={workspace.workspace_area} 
                onChange={(e)=>{SetWorkspace({...workspace,workspace_area :e.target.value});}}/>
              <select className="form_select" id="floor_area_workspace_select" required value={workspace.workspace_area_unit_id} 
                onChange={(e)=>{SetWorkspace({...workspace,workspace_area_unit_id: e.target.value});}}>
                <option value=""></option>
                {props.selectItemsList[0].areaUnitsList.map((area)=>{
                  return <option key={area.id} value={area.id}>{area.name}</option>;
                })}
              </select>
              <label className="form_label label2">SHARING<br/>WITH OTHERS</label>
              <a id="sharing_yes_button" className={workspace.sharing ? 'form_button active':'form_button inactive'} href="#" onClick={function () {
                SetWorkspace({...workspace,sharing: true});
                document.getElementById('nb_people_input').disabled = false;
              }}>Yes</a>
              <a id="sharing_no_button" className={workspace.sharing ? 'form_button inactive':'form_button active'} href="#" onClick={function () {
                SetWorkspace({...workspace,sharing: false,nb_people_sharing:0});
                document.getElementById('nb_people_input').disabled = true;
              }}>No</a>
              
              <label className="form_label label3">WITH HOW<br/>MANY PEOPLE?</label>
              <input id="nb_people_input" className="form_input" type="number" required min="0" step="1" value={workspace.nb_people_sharing} 
                onChange={(e)=>{SetWorkspace({...workspace,nb_people_sharing: e.target.value});}}/>
              <label className="form_label label4">USING SMART<br/>POWER STRIP?</label>
              <a id="smart_yes_button" className={workspace.plugged_smart_strip ? 'form_button active':'form_button inactive'} href="#" onClick={function () {
                SetWorkspace({...workspace,plugged_smart_strip: true});
              }}>Yes</a>
              <a id="smart_no_button" className={workspace.plugged_smart_strip ? 'form_button inactive':'form_button active'} href="#" onClick={function () {
                SetWorkspace({...workspace,plugged_smart_strip: false});
              }}>No</a>
            </div>
          </div>;
        }
      case 8:
        var response_workspace_1 = Req.VerifierWorkspace_1(workspace);
        if(!response_workspace_1.res)
        {
          toast.error(response_workspace_1.msj,{position:toast.POSITION.TOP_CENTER});
          document.getElementById('chek_error').click();
          return <div className="loader_blue"></div>;
        }
        else
        {
          return<div style={{pointerEvents: !workspace.apply?'none':'auto'}}>
            <div className="track_form_title">
              <span>WORKSPACE</span>
            </div>
            <label className="form_label label1">WORKING IN<br/>DAYLIGHT</label>
            <input id="daylight_input" className="form_input" type="number"  min="0" max="100" step="0.1" required value={workspace.daylight_work_percent} 
              onChange={(e)=>{SetWorkspace({...workspace,daylight_work_percent: e.target.value});}}/>
            <label className="form_label label_second1">% of time</label>
            <label className="form_label label2">AMBIENT<br/>LIGHTING</label>
            <select className="form_select" id="ambient_lighting_select" required value={workspace.ambient_lighting_id} 
              onChange={(e)=>{SetWorkspace({...workspace,ambient_lighting_id: e.target.value});}}>
              <option value=""></option>
              {props.selectItemsList[0].lightingList.map((lighting)=>{
                return <option key={lighting.id} value={lighting.id}>{lighting.name}</option>;
              })}
            </select>
            <label className="form_label label3">DESK<br/>LIGHTING</label>
            <select className="form_select" id="desk_lighting_select" required value={workspace.desk_lighting_id} 
              onChange={(e)=>{SetWorkspace({...workspace, desk_lighting_id : e.target.value});}}>
              <option value=""></option>
              {props.selectItemsList[0].lightingList.map((lighting)=>{
                return <option key={lighting.id} value={lighting.id}>{lighting.name}</option>;
              })}
            </select>
            <label className="form_label label4">VIDEO CALLS<br/>LIGHTING</label>
            <a id="video_yes_button" className={workspace.video_call_lighting ? 'form_button active':'form_button inactive'} href="#" onClick={function () {
              SetWorkspace({...workspace,video_call_lighting: true});
            }}>Yes</a>
            <a id="video_no_button" className={workspace.video_call_lighting ? 'form_button inactive':'form_button active'} href="#" onClick={function () {
              SetWorkspace({...workspace,video_call_lighting: false});
            }}>No</a>
          </div>;
        }
      case 9:
        var response_workspace_2 = Req.VerifierWorkspace_2(workspace);
        if(!response_workspace_2.res)
        {
          toast.error(response_workspace_2.msj,{position:toast.POSITION.TOP_CENTER});
          document.getElementById('chek_error').click();
          return <div className="loader_blue"></div>;
        }
        else
        {
          return<div>
            <div className="track_form_title">
              <span>DIGITAL</span>
            </div>
            <label className="form_label not_app_label">Not Applicable</label>
            <input type="checkbox" className="form_chekbox" id="digital_not_app" checked={!digital.apply?'checked':''} 
              onClick={()=>{
                SetDigital({...digital,apply:!digital.apply,hrs_daily_online_calling:0,percent_video_calling:0,main_internet_id:'',main_device_id:'',
                  monitor:false,microphone:false,ext_camera:false,ext_sound:false,zoom:0,google:0,teams:0,slack:0,other:0});
              }}
            />
            <div style={{pointerEvents: !digital.apply?'none':'auto'}}>
              <label className="form_label label1">MAIN INTERNET<br/>CONNEXION</label>
              <select className="form_select" id="main_internet_select" required value={digital.main_internet_id} 
                onChange={(e)=>{SetDigital({...digital,main_internet_id: e.target.value});}}>
                <option value=""></option>
                {props.selectItemsList[0].mainInternetList.map((internet)=>{
                  return <option key={internet.id} value={internet.id}>{internet.name}</option>;
                })}
              </select>
              <label className="form_label label2">MAIN DEVICE</label>
              <select className="form_select" id="devices_select" required value={digital.main_device_id} 
                onChange={(e)=>{SetDigital({...digital,main_device_id: e.target.value});}}>
                <option value=""></option>
                {props.selectItemsList[0].devicesList.map((device)=>{
                  return <option key={device.id} value={device.id}>{device.name}</option>;
                })}
              </select>
              <label className="form_label label3">PERIPHERALS</label>
              <a id="monitor_button" className={digital.monitor ? 'form_button active' : 'form_button inactive'} href="#" onClick={function () {
                if(!digital.monitor)
                  SetDigital({...digital,monitor: true});
                else
                  SetDigital({...digital,monitor: false});

              }}>Monitor</a>
              <a id="microphone_button" className={digital.microphone ? 'form_button active' : 'form_button inactive'} href="#" onClick={function () {
                if(!digital.microphone)
                  SetDigital({...digital,microphone: true});
                else
                  SetDigital({...digital,microphone: false});
              }}>Microphone</a>
              <a id="camera_button" className={digital.ext_camera ? 'form_button active' : 'form_button inactive'} href="#" onClick={function () {
                if(!digital.ext_camera)
                  SetDigital({...digital,ext_camera: true});
                else
                  SetDigital({...digital,ext_camera: false});
              }}>Camera</a>
              <a id="sound_button" className={digital.ext_sound ? 'form_button active' : 'form_button inactive'} href="#" onClick={function () {
                if(!digital.ext_sound)
                  SetDigital({...digital,ext_sound: true});
                else
                  SetDigital({...digital,ext_sound: false});
              }}>Ext. Sound<br/>system</a>
            </div>
          </div>;
        }
      case 10:
        var response_digital_1 = Req.VerifierDigital_1(digital);
        if(!response_digital_1.res)
        {
          toast.error(response_digital_1.msj,{position:toast.POSITION.TOP_CENTER});
          document.getElementById('chek_error').click();
          return <div className="loader_blue"></div>;
        }
        else
        {
          return<div style={{pointerEvents: !digital.apply?'none':'auto'}}>
            <div className="track_form_title">
              <span>DIGITAL</span>
            </div>
            <label className="form_label label1">DAILY ONLINE<br/>CALLING</label>
            <select className="form_select" id="digital_nb_hours_select" required value={digital.hrs_daily_online_calling} 
              onChange={(e)=>{SetDigital({...digital,hrs_daily_online_calling: e.target.value});}}>
              {props.selectItemsList[0].onlineCallingHoursList.map((hours)=>{
                return <option key={hours} value={hours}>{hours}</option>;
              })}
            </select>
            <label className="form_label label_second1">hours</label>
      
            <label className="form_label label2">VIDEO CALLING<br/>USAGE RATE</label>
            <input id="video_calling_input" className="form_input" type="number"  min="0" max="100" step="0.1" required value={digital.percent_video_calling} 
              onChange={(e)=>{SetDigital({...digital,percent_video_calling: e.target.value});}}/>
            <label className="form_label label_second2">%</label>
            <label className="form_label"id="zoom_label">ZOOM</label>
            <input type="range" min="0" max="100"  id="zoom_slider" value={digital.zoom} className="slider"  onInput={function (){
              var slider = document.getElementById('zoom_slider');
              var limit = 100 - digital.google - digital.teams - digital.slack;
              if(slider.value > limit)
              {
                slider.value = limit;
              }
              SetDigital({...digital,zoom: slider.value,other:limit-slider.value});
            }}/>
            <div className="sliderValue" id="zoom_slider_value">{digital.zoom}%</div>
            <label className="form_label" id="google_label">GOOGLE MEET</label>
            <input type="range" min="0" max="100"  id="google_slider" value={digital.google} className="slider" onInput={function (){
              var slider = document.getElementById('google_slider');
              var limit = 100 - digital.zoom - digital.teams - digital.slack;
              if(slider.value > limit)
              {
                slider.value = limit;
              }
              SetDigital({...digital,google: slider.value ,other:limit-slider.value}); 
              
            }}/>
            <div className="sliderValue" id="google_slider_value">{digital.google}%</div>
            <label className="form_label" id="teams_label">TEAMS</label>
            <input type="range" min="0" max="100"  id="teams_slider" value={digital.teams} className="slider" onInput={function (){
              var slider = document.getElementById('teams_slider');
              var limit = 100 - digital.zoom - digital.google - digital.slack;
              if(slider.value > limit)
              {
                slider.value = limit;
              }
              SetDigital({...digital,teams: slider.value,other:limit-slider.value});
            }}/>
            <div className="sliderValue" id="teams_slider_value">{digital.teams}%</div>
            <label className="form_label" id="slack_label">SLACK</label>
            <input type="range" min="0" max="100"  id="slack_slider" value={digital.slack} className="slider" onInput={function (){
              var slider = document.getElementById('slack_slider');
              var limit = 100 - digital.zoom - digital.google - digital.teams;
              if(slider.value > limit)
              {
                slider.value = limit;
              }
              SetDigital({...digital,slack: slider.value,other:limit-slider.value});
              
            }}/>
            <div className="sliderValue" id="slack_slider_value">{digital.slack}%</div>
          </div>;
        }
      case 11:
        var response_digital_2 = Req.VerifierDigital_2(digital);
        if(!response_digital_2.res)
        {
          toast.error(response_digital_2.msj,{position:toast.POSITION.TOP_CENTER});
          document.getElementById('chek_error').click();
          return <div className="loader_blue"></div>;
        }
        else
        {
          return<div>
            <div className="track_form_title">
              <span>PURCHASES</span>
            </div>
            <label className="form_label not_app_label">Not Applicable</label>
            <input type="checkbox" className="form_chekbox" id="purchases_not_app" checked={!purchases.apply?'checked':''} 
              onClick={()=>{
                SetPurchases({...purchases,apply:!purchases.apply,regular_desk:0, small_desk :0, bookcase :0, cabinet :0, task_chair :0,visitor_chair :0, 
                  arm_chair :0, sofa :0, smartphone :0, tablet :0, laptop :0, desktop :0, monitor :0, microphone :0, ext_camera :0, ext_sound_system:0});
              }}
            />
            <div style={{pointerEvents: !purchases.apply?'none':'auto'}}>
              <label className="form_label label11">REGULAR DESK</label>
              <input id="regular_desk_input" className="form_input" type="number" required  min="0" step="1" value={purchases.regular_desk} 
                onChange={(e)=>{SetPurchases({...purchases,regular_desk: e.target.value});}}/>
              <label className="form_label label21">BOOKCASE</label>
              <input id="bookcase_input" className="form_input" type="number" required min="0" step="1" value={purchases.bookcase} 
                onChange={(e)=>{SetPurchases({...purchases,bookcase: e.target.value});}}/>
              <label className="form_label label31">TASK CHAIR</label>
              <input id="task_chair_input" className="form_input" type="number" required  min="0" step="1" value={purchases.task_chair}
                onChange={(e)=>{SetPurchases({...purchases,task_chair: e.target.value});}}/>
              <label className="form_label label41">ARM CHAIR</label>
              <input id="arm_chair_input" className="form_input" type="number" required  min="0" step="1" value={purchases.arm_chair} 
                onChange={(e)=>{SetPurchases({...purchases,arm_chair: e.target.value});}}/>
              <label className="form_label label12">SMALL DESK</label>
              <input id="small_desk_input" className="form_input" type="number" required  min="0" step="1" value={purchases.small_desk} 
                onChange={(e)=>{SetPurchases({...purchases,small_desk: e.target.value});}}/>
              <label className="form_label label22">CABINET</label>
              <input id="cabinet_input" className="form_input" type="number" required  min="0" step="1" value={purchases.cabinet} 
                onChange={(e)=>{SetPurchases({...purchases,cabinet: e.target.value});}}/>
              <label className="form_label label32">VISITOR CHAIR</label>
              <input id="visitor_input" className="form_input" type="number" required  min="0" step="1" value={purchases.visitor_chair} 
                onChange={(e)=>{SetPurchases({...purchases,visitor_chair: e.target.value});}}/>
              <label className="form_label label42">SOFA</label>
              <input id="sofa_input" className="form_input" type="number" required  min="0" step="1" value={purchases.sofa} 
                onChange={(e)=>{SetPurchases({...purchases,sofa: e.target.value});}}/>
            </div>
          </div>;
        }
      case 12:
        var response_purchases_1 = Req.VerifierPurchases_1(purchases);
        if(!response_purchases_1.res)
        {
          toast.error(response_purchases_1.msj,{position:toast.POSITION.TOP_CENTER});
          document.getElementById('chek_error').click();
          return <div className="loader_blue"></div>;
        }
        else
        {
          return <div style={{pointerEvents: !purchases.apply?'none':'auto'}}>
            <div className="track_form_title">
              <span>PURCHASES</span>
            </div>
            <label className="form_label label11">SMARTPHONE</label>
            <input id="smartphone_input" className="form_input" type="number" required min="0" step="1" value={purchases.smartphone} 
              onChange={(e)=>{SetPurchases({...purchases,smartphone: e.target.value});}}/>
            <label className="form_label label21">LAPTOP</label>
            <input id="laptop_input" className="form_input" type="number" required min="0" step="1" value={purchases.laptop} 
              onChange={(e)=>{SetPurchases({...purchases,laptop: e.target.value});}}/>
            <label className="form_label label31">MONITOR</label>
            <input id="monitor_input" className="form_input" type="number" required  min="0" step="1" value={purchases.monitor} 
              onChange={(e)=>{SetPurchases({...purchases,monitor: e.target.value});}}/>
            <label className="form_label label41">EXT. CAMERA</label>
            <input id="camera_input" className="form_input" type="number" required  min="0" step="1" value={purchases.ext_camera} 
              onChange={(e)=>{SetPurchases({...purchases,ext_camera: e.target.value});}}/>
            <label className="form_label label12">TABLET</label>
            <input id="tablet_input" className="form_input" type="number" required  min="0" step="1" value={purchases.tablet} 
              onChange={(e)=>{SetPurchases({...purchases,tablet: e.target.value});}}/>
            <label className="form_label label22">DESKTOP</label>
            <input id="desktop_input" className="form_input" type="number" required min="0" step="1" value={purchases.desktop} 
              onChange={(e)=>{SetPurchases({...purchases,desktop: e.target.value});}}/>
            <label className="form_label label32">MICROPHONE</label>
            <input id="microphone_input" className="form_input" type="number" required min="0" step="1" value={purchases.microphone} 
              onChange={(e)=>{SetPurchases({...purchases,microphone: e.target.value});}}/>
            <label className="form_label label42">EXT. SOUND</label>
            <input id="sound_input" className="form_input" type="number" required  min="0" step="1" value={purchases.ext_sound_system} 
              onChange={(e)=>{SetPurchases({...purchases,ext_sound_system: e.target.value});}}/>
          </div>;
        }
      case 13 :
        var response_purchases_2 = Req.VerifierPurchases_2(purchases);
        if(!response_purchases_2.res)
        {
          toast.error(response_purchases_2.msj,{position:toast.POSITION.TOP_CENTER});
          document.getElementById('chek_error').click();
        }
        else
        {
          var onSuccess = () => {
            location.reload();  
          };
          var onfail = () => {
            toast.error('Fail',{position:toast.POSITION.TOP_CENTER});
          };
          props.addTrack(index.id, JSON.stringify(schedule),JSON.stringify(travel),JSON.stringify(commute),
            JSON.stringify(space),JSON.stringify(workspace),JSON.stringify(digital),JSON.stringify(purchases)
            ,onSuccess,onfail);
        }
        return <div className="loader_blue"></div>;
    }
  }
};
const mapStateToProps = state => ({
  user: state.employees.list,
  selectItemsList: state.selectItems.list,
  heatingFuelsUnitsList: state.heatingFuelsUnits.list,
  trackRes : state.track.response,
  trackItem: state.track.itemList,
});

const mapActionToProps = {
  userConnected: actions.userConnected,
  fetchAllSelectItems: selectItems_actions.fetchAllSelectItems,
  fetchHeatingFuelsUnits: heatingFuelsUnits_actions.fetchHeatingFuelsUnits,
  addTrack: track_actions.addTrack,
  trackById: track_actions.trackById,
};

TrackForm.propTypes = {
  index : PropTypes.object.isRequired,
  userConnected: PropTypes.func,
  user: PropTypes.array,
  fetchAllSelectItems: PropTypes.func,
  selectItemsList: PropTypes.array,
  fetchHeatingFuelsUnits: PropTypes.func,
  heatingFuelsUnitsList: PropTypes.array,
  addTrack: PropTypes.func,
  trackRes: PropTypes.bool,
  trackById: PropTypes.func,
  trackItem: PropTypes.array,
};

export default connect(mapStateToProps,mapActionToProps)(TrackForm);
