import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SettingButton extends Component {
  render() {
    const {
      name
    } = this.props;

    switch(name) {
      case 'black':
        return <img src={'/setting_black_button.png'} />;
      case 'white':
        return <img src={'/setting_white_button.png'} />;
    }  
  }
}

SettingButton.propTypes = {
  name : PropTypes.string.isRequired
};


export default SettingButton;
