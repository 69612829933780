import api from '../api/heatingFuelsUnits';
export const ACTION_TYPES={
  FETCH_HEATING_FUELS_UNITS : 'FETCH_HEATING_FUELS_UNITS'
};

export const fetchHeatingFuelsUnits = (id) => dispatch => {
  api.heatingFuelsUnits().fetchHeatingFuelsUnits(id)
    .then(res => {
      dispatch({ 
        type: ACTION_TYPES.FETCH_HEATING_FUELS_UNITS,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};

