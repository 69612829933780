import React, { Component } from 'react';
import PropTypes from 'prop-types';

class OffsetButton extends Component {
  render() {
    const {
      name
    } = this.props;

    switch(name) {
      case 'white':
        return <img src={'/offset_button.png'} />;
      case 'black':
        return <img src={'/offset_black_button.png'} />;
      case 'green':
        return <img src={'/offset_green_button.png'} />;
    }  
  }
}

OffsetButton.propTypes = {
  name : PropTypes.string.isRequired
};


export default OffsetButton;

