import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ThreeTons extends Component {
  render() {
    const {
      index
    } = this.props;

    switch(index) {
      case 3:
        return <img src={'/3tons_clicked.png'} />;
      default:
        return <img src={'/3tons.png'} />;
    }  
  }
}

ThreeTons.propTypes = {
  index : PropTypes.number.isRequired
};


export default ThreeTons;
