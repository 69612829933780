import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import { useHistory } from 'react-router-dom'; 
import 'react-toastify/dist/ReactToastify.css';
import './LoginContainer.css';
import './Query.css';
import 'font-awesome/css/font-awesome.min.css';
import * as actions from '../../actions/employees';
import Hexagon from 'react-hexagon';
import VerifierLogin from  './LoginRequired';
const LoginContainer = (props)=>{
  const history = useHistory();
  toast.configure();


  const onSuccess = () => {
    history.push('/welcome');   
  };
  const onfail = () => {
    document.getElementById('login_block').style.display='block';
    document.getElementById('login_loading').style.display='none';
    toast.error('Authentication failed',{position:toast.POSITION.TOP_CENTER});
  };

  return (
    <div>
      <div id="login_block">
        <Hexagon  style={{stroke: '#55b79500',fill:'#55b79521'}}  className="login_hex_1" />
        <Hexagon  style={{stroke: '#55b79500',fill:'#55b79521'}}  className="login_hex_2" />
        <Hexagon  style={{stroke: '#55b79500',fill:'#55b79521'}}  className="login_hex_3" />
        <Hexagon  style={{stroke: '#55b79500',fill:'#55b79521'}}  className="login_hex_4" />
        <div className="title">
          <span>Work from Anywhere, Carbon Free</span>
        </div>
        <div className="text">
          <span>Track, reduce, and offset carbon emitted while working from home, in the office, in shared spaces, on the road…</span>
        </div>
        <div className="login_container">
          <div className="login_title">
            <span>LOGIN</span>
          </div>
          <div className="social_container">
            <div className="google">
              <a href="#"><i className="social_icon fa fa-google"></i></a>
            </div>
            <div className="facebook">
              <a href="#"><i className="social_icon fa fa-facebook"></i></a>
            </div>
            <div className="linkedin">
              <a href="#"><i className="social_icon fa fa-linkedin"></i></a>
            </div>
          </div>
          <div>
            <label className="email_label">EMAIL</label>
            <input className="email_input" id="email" type="email" required/>
            <label className="password_label">PASSWORD</label>
            <input className="password_input" id="password" type="password" required />
            <input className="remember_me_checkbox" id="rememberMe" type="checkbox" />
            <div className="remember_me">
              <span>Remember me</span>
            </div>
            <div className="forgot_password">
              <a href="#">Forgot password?</a>
            </div>
            <a href="#" id="login_button" on onClick={function () {
              var email = document.getElementById('email').value;
              var password = document.getElementById('password').value;
              var response= VerifierLogin(email,password);
              if(!response.res)
              {
                toast.error(response.msj,{position:toast.POSITION.TOP_CENTER});
              }
              else
              {
                document.getElementById('login_block').style.display='none';
                document.getElementById('login_loading').style.display='block';
                props.login(email,password,onSuccess,onfail);
              } 
            }}>Login</a>
          </div>
          <div className="new_account">
            <a href="/register">Signup</a>
          </div>
          <div className="terms_conditions">
            <a href="#">Terms and conditions and privacy policy</a>
          </div>
        </div>
      </div>
      <div id="login_loading" hidden><div className="track_menu_loader_blue"></div></div>
    </div>
  );
};

const mapStateToProps = state =>({
  response : state.employees.response
});
const mapActionToProps={
  login : actions.login 
};

LoginContainer.propTypes = {
  login: PropTypes.func,
};
export default connect(mapStateToProps,mapActionToProps)(LoginContainer);
