import { ACTION_TYPES } from '../actions/employees';
const initialState = {
  list:[]
};
export const employees = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOGIN:
      return {
        ...state,
        list: [...action.payload]
      };
    case ACTION_TYPES.REGISTER:
      return {
        ...state,
        list: [...action.payload]
      }; 
    case ACTION_TYPES.USERCONNECTED:
      return {
        ...state,
        list :[...action.payload]
      };
    default:
      return state;
  }
};
