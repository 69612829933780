import './SettingsForm.css';
import React ,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import NavigationButton from '../../components/Icons/NavigationButton';
import * as selectItems_actions from '../../actions/selectItems';
import * as employee_actions from '../../actions/employees';
import * as settings_actions from '../../actions/settings';
import {toast} from 'react-toastify';
import VerifierSettings from './SettingsRequired';

const SettingsForm = (props)=> {
  const {
    name,
  } = props; 

  /*************** date now *****************/
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;
  /**************************************** */

  toast.configure();

  var onSuccess = () => {
    location.reload();  
  };
  var onfail = () => {
    toast.error('Fail',{position:toast.POSITION.TOP_CENTER});
  };

  var intialOrganizationSub ={
    id:0,
    year :0,
    employee_id :'',
    package_id :'',
    organization_id :'',
    start_date:'',
    end_date:'',
    total_workdays :260,
    daily_work_hours :8
  };
  var intialEmployees={
    languages_id:'',
    date_format_id:'',
    units_id:''
  };

  var [organizationSub,SetOrganizationSub]=useState(intialOrganizationSub);
  var [employees,SetEmployees]=useState(intialEmployees);
  var NbHours = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24];

  useEffect(() => {
    props.fetchAllSelectItems();
    props.userConnected();
    props.fetchAllSettings();
    SetOrganizationSub(props.settingsList == undefined ? intialOrganizationSub : props.settingsList[0].organization_sub);
    SetEmployees(props.settingsList == undefined ? intialEmployees : props.settingsList[0].employees);
  }, [props.settingsList== undefined?0:props.settingsList.length]);

  if(props.selectItemsList.length == 0 || props.user.length == 0 || props.settingsList.length == 0)
  {
    return <div className="loader_green"></div>; 
  }
  else
  {
    switch(name) {
      case 'organization':
        return<div style={{pointerEvents:'none'}}>
          <div className="settings_form_title">
            <span>ORGANIZATION</span>
          </div>
          <label className="form_label label1">ORGANIZATION<br/>NAME</label>
          <input id="organization_name_input"  className="form_input"  value={props.user[0].organization_name.toUpperCase()}/>
          <label className="form_label label2">REPORTING<br/>YEAR</label>
          <input id="reporting_input" className="form_input" value={props.user[0].year}/>
          <label className="form_label label3">CARBON OFFSET<br/>ALLOWANCE</label>
          <input id="carbon_offset_input" className="form_input"  value={props.user[0].carbon_offset_allowance}/>
          <label className="form_label_second" id="carbon_offset_units">tons of CO2e<br/>per year</label>
        </div>;
      case 'employement':
        return<div>
          <div id="emp_block">
            <div className="settings_form_title">
              <span>EMPLOYEMENT</span>
            </div>
            <label className="form_label label1">START DATE</label>
            <input id="start_input" className="form_input" type="date"  required value={organizationSub.start_date} 
              onChange={(e)=>{SetOrganizationSub({...organizationSub,start_date:e.target.value});}}/>
            <label className="form_label label2">TOTAL WORKDAYS</label>
            <input id="total_input" className="form_input" type="number" min="0" required value={organizationSub.total_workdays} 
              onChange={(e)=>{SetOrganizationSub({...organizationSub,total_workdays:e.target.value});}}/>
            <label className="form_label label3">AVERAGE DAILY<br/>WORK HOURS</label>
            <select className="form_select" id="daily_work_hours_select" required value={organizationSub.daily_work_hours} 
              onChange={(e)=>{SetOrganizationSub({...organizationSub,daily_work_hours: e.target.value});}}>
              {NbHours.map((hours)=>{
                return <option key={hours} value={hours}>{hours}</option>;
              })}
            </select>
            <label className="form_label label4">EMPLOYEMENT<br/>END DATE</label>
            <input id="employement_input" className="form_input" type="date"  required value={organizationSub.end_date} 
              onChange={(e)=>{SetOrganizationSub({...organizationSub,end_date:e.target.value});}}/>
            <a href="#" className="save_employement" onClick={function () {
              var response = VerifierSettings('emp',organizationSub,today,employees);
              if(!response.res)
              {
                toast.error(response.msj,{position:toast.POSITION.TOP_CENTER});
              }
              else
              {
                document.getElementById('emp_block').style.display='none';
                document.getElementById('emp_loading').style.display='block';
                props.updateOrganizationSub(JSON.stringify(organizationSub),onSuccess,onfail);
              }
            }}><NavigationButton name="save_green"/></a>
          </div>
          <div id="emp_loading" hidden><div className="loader_green"></div></div>
        </div>;
      case 'preferences':
        return<div>
          <div id="pref_block">
            <div className="settings_form_title">
              <span>PREFERENCES</span>
            </div>
            <label className="form_label label1">LANGUAGE</label>
            <select className="form_select" id="language_select" required value={employees.languages_id} 
              onChange={(e)=>{SetEmployees({...employees,languages_id:e.target.value});}}>
              {props.selectItemsList[0].languagesList.map((lang)=>{
                return <option key={lang.id} value={lang.id}>{lang.name}</option>;
              })}
            </select>
            <label className="form_label label2">DATE FORMAT</label>
            <select className="form_select" id="date_format_select" required value={employees.date_format_id} 
              onChange={(e)=>{SetEmployees({...employees,date_format_id:e.target.value});}}>
              {props.selectItemsList[0].dateFormatList.map((DF)=>{
                return <option key={DF.id} value={DF.id}>{DF.name}</option>;
              })}
            </select>
            <label className="form_label label3">UNITS</label>
            <select className="form_select" id="units_select" required value={employees.units_id} 
              onChange={(e)=>{SetEmployees({...employees,units_id:e.target.value});}}>
              {props.selectItemsList[0].unitsList.map((units)=>{
                return <option key={units.id} value={units.id}>{units.name}</option>;
              })}
            </select>
            <a href="#" className="save_preferences" onClick={function () {
              var response = VerifierSettings('pref',organizationSub,today,employees);
              if(!response.res)
              {
                toast.error(response.msj,{position:toast.POSITION.TOP_CENTER});
              }
              else
              {
                document.getElementById('pref_block').style.display='none';
                document.getElementById('pref_loading').style.display='block';
                props.UpdateEmployees(JSON.stringify(employees),onSuccess,onfail);
              }
            }}><NavigationButton name="save_green"/></a>
          </div>
          <div id="pref_loading" hidden><div className="loader_green"></div></div> 
        </div>;
    }
  }
};

const mapStateToProps = state => ({
  selectItemsList: state.selectItems.list,
  user: state.employees.list,
  settingsList: state.settings.list,
  updateEmployeesRes : state.settings.updateEmployeesRes,
  updateOrganizationSubRes : state.settings.updateOrganizationSubRes,
});

const mapActionToProps = {
  fetchAllSelectItems: selectItems_actions.fetchAllSelectItems,
  userConnected: employee_actions.userConnected,
  fetchAllSettings: settings_actions.settingsList,
  UpdateEmployees: settings_actions.UpdateEmployees,
  updateOrganizationSub: settings_actions.updateOrganizationSub,
};

SettingsForm.propTypes = {
  name : PropTypes.string.isRequired,
  fetchAllSelectItems: PropTypes.func,
  selectItemsList: PropTypes.array,
  userConnected: PropTypes.func,
  user: PropTypes.array,
  fetchAllSettings: PropTypes.func,
  settingsList: PropTypes.array,
  UpdateEmployees: PropTypes.func,
  updateEmployeesRes: PropTypes.bool,
  updateOrganizationSub: PropTypes.func,
  updateOrganizationSubRes: PropTypes.bool,
};

export default connect(mapStateToProps,mapActionToProps)(SettingsForm);
