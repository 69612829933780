import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SocialMedia extends Component {
  render() {
    const {
      name
    } = this.props;

    switch(name) {
      case 'mail':
        return <img src={'/mail_button.png'} />;
      case 'facebook':
        return <img src={'/facebook_button.png'} />;
      case'instagram':
        return <img src={'/instagram_button.png'} />;
      case'linkedin':
        return <img src={'/linkedin_button.png'} />;
      case'twiter':
        return <img src={'/twiter_button.png'} />;
    }  
  }
}

SocialMedia.propTypes = {
  name : PropTypes.string.isRequired
};


export default SocialMedia;
