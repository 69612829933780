import api from '../api/dashboard';
export const ACTION_TYPES={
  EMP_TRACK : 'EMP_TRACK'
};

export const employeeTrack = () => dispatch => {
  api.dashboard().employeeTrack()
    .then(res => {
      dispatch({
        type: ACTION_TYPES.EMP_TRACK,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};

