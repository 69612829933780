import React , { Component } from 'react';
import PropTypes from 'prop-types';

class AddButton extends Component {
  render() {
    const {
      name
    } = this.props;

    switch(name) {
      case 'white':
        return <img src={'/add_button.png'} />;
      case 'green':
        return <img src={'/add_green_button.png'} />;
      case 'blue' :
        return <img src={'/add_blue_button.png'} />;
    }  
  }
}

AddButton.propTypes = {
  name : PropTypes.string.isRequired
};


export default AddButton;

