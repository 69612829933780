import api from '../api/track';
export const ACTION_TYPES={
  ADD : 'ADD',
  ALL:'ALL',
  BY_ID:'BY_ID',
  DELETE:'DELETE'
};

export const addTrack = (id,schedule_json,travel_json,commute_json,space_json,workspace_json,
  digital_json,purchases_json,onSuccess,onFail) => dispatch => {
  
  api.track().addTrack(id,schedule_json,travel_json,commute_json,space_json,workspace_json,digital_json,purchases_json) 
    .then(res => {
      if(res.data.success)
      {
        onSuccess();
      }
      else
      {
        onFail();
      }
      dispatch({
        type: ACTION_TYPES.ADD,
        payload: res.data.success
      });
    })
    .catch(err => console.log(err));
};


export const trackList = () => dispatch => {
  api.track().trackList()
    .then(res => {
      dispatch({
        type: ACTION_TYPES.ALL,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};

export const trackById = (id) => dispatch => {
  api.track().trackById(id)
    .then(res => {
      dispatch({
        type: ACTION_TYPES.BY_ID,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};

export const deleteTrack = (id,onSuccess,onFail) => dispatch => {
  api.track().deleteTrack(id)
    .then(res => {
      console.log(res);
      if(res.data.success)
      {
        onSuccess(res.data.message);
      }
      else
      {
        onFail(res.data.message);
      }
      dispatch({
        type: ACTION_TYPES.DELETE,
        payload: res.data.success
      });
    })
    .catch(err => console.log(err));
};

