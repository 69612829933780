import { ACTION_TYPES } from '../actions/track';
const initialState = {
  response:false,
  List:[],
  itemList:[],
  deleteResponse:false
};
export const track = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.ADD:
      return {
        ...state,
        response: [...action.payload]
      };
    case ACTION_TYPES.ALL:
      return {
        ...state,
        list: [...action.payload]
      };
    case ACTION_TYPES.BY_ID:
      return {
        ...state,
        itemList: [...action.payload]
      };
    case ACTION_TYPES.DELETE:
      return {
        ...state,
        deleteResponse: [...action.payload]
      };
    default:
      return state;
  }
};
