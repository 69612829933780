import './Settings.css';
import React,{useState} from 'react';
import TrackButton from '../../components/Icons/TrackButton';
import InfoButton from '../../components/Icons/InfoButton';
import WfButton from '../../components/Icons/WfButton';
import OffsetButton from '../../components/Icons/OffsetButton';
import SettingButton from '../../components/Icons/SettingButton';
import SocialMedia from '../../components/Icons/SocialMedia';
import AccountButton from '../../components/Icons/AccountButton';
import NotificationButton from '../../components/Icons/NotificationButton';
import SettingsForm from './SettingsForm';

const Settings = ()=> {
  const [section , SetSection]=useState('organization');
  return (
    <div>
      {/* account notification */}

      <a href="#" className="account_button"><AccountButton /></a>
      <a href="#" className="notification_button"><NotificationButton /></a>


      {/* left menu */}
      
      <div className="left_menu_block">
        <a href="./dashboard" className="wf_white_button"><WfButton name="white"/><br/>DASHBOARD</a> 
        <a href="./track" className="track_white_button"><TrackButton name="white"/><br/>TRACK</a>
        <a href="#" className="info_button"><InfoButton/><br/>LEARN</a>
        <a href="./offset" className="offset_white_button"><OffsetButton name="white"/><br/>OFFSET</a>
        <a href="./settings" className="setting_black_button"><SettingButton name="black"/><br/>SETTINGS</a>
      </div>
      <div className="social_media">
        <a href="#" className="db_mail"><SocialMedia name="mail"/></a>
        <a href="#" className="db_facebook"><SocialMedia name="facebook"/></a>
        <a href="#" className="db_instagram"><SocialMedia name="instagram"/></a>
        <a href="#" className="db_linkedin"><SocialMedia name="linkedin"/></a>
        <a href="#" className="db_twiter"><SocialMedia name="twiter"/></a>
      </div>

      {/* Settings menu */}
      <div className="settings_menu block">
        <div className="settings_menu_title">SETTINGS</div>
        <a  href="#" className={section =='organization' ? 'settings_sections settings_sections_1 setting_active'
          :'settings_sections settings_sections_1'} onClick={function () {
          SetSection('organization');
        }}>
          <div className="settings_sections_title">ORGANIZATION</div>
        </a>
        <a href="#" className={section =='employement' ? 'settings_sections settings_sections_2 setting_active'
          :'settings_sections settings_sections_2'} onClick={function () {
          SetSection('employement');
        }}>
          <div className="settings_sections_title">EMPLOYEMENT</div>
        </a>
        <a href="#" className={section =='preferences' ? 'settings_sections settings_sections_3 setting_active'
          :'settings_sections settings_sections_3'} onClick={function () {
          SetSection('preferences');
        }}>
          <div  className="settings_sections_title">PREFERENCES</div>
        </a>
      </div>
      {/* Settings form */}
      <div className="Settings_form block">
        <SettingsForm name={section} />
      </div>

    </div>
  );

};


export default Settings;
