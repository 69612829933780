import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TwoTons extends Component {
  render() {
    const {
      index
    } = this.props;

    switch(index) {
      case 2:
        return <img src={'/2tons_clicked.png'} />;
      default:
        return <img src={'/2tons.png'} />;
    }  
  }
}

TwoTons.propTypes = {
  index : PropTypes.number.isRequired
};


export default TwoTons;
