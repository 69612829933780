import './Offset.css';
import React,{useState} from 'react';
import TrackButton from '../../components/Icons/TrackButton';
import InfoButton from '../../components/Icons/InfoButton';
import WfButton from '../../components/Icons/WfButton';
import OffsetButton from '../../components/Icons/OffsetButton';
import SettingButton from '../../components/Icons/SettingButton';
import SocialMedia from '../../components/Icons/SocialMedia';
import AccountButton from '../../components/Icons/AccountButton';
import NotificationButton from '../../components/Icons/NotificationButton';
import Map from '../../components/Icons/Map';
import OneTon from '../../components/Icons/OneTon';
import TwoTons from '../../components/Icons/TwoTons';
import ThreeTons from '../../components/Icons/ThreeTons';


const Track = ()=> {
  const [nbTon, setNbTon] = useState(1);
  return (
    <div>
      {/* account notification */}

      <a href="#" className="account_button"><AccountButton /></a>
      <a href="#" className="notification_button"><NotificationButton /></a>


      {/* left menu */}
      
      <div className="left_menu_block">
        <a href="./dashboard" className="wf_white_button"><WfButton name="white"/><br/>DASHBOARD</a> 
        <a href="./track" className="track_white_button"><TrackButton name="white"/><br/>TRACK</a>
        <a href="#" className="info_button"><InfoButton/><br/>LEARN</a>
        <a href="./offset" className="offset_black_button"><OffsetButton name="black"/><br/>OFFSET</a>
        <a href="./settings" className="setting_white_button"><SettingButton name="white"/><br/>SETTINGS</a>
      </div>
      <div className="social_media">
        <a href="#" className="db_mail"><SocialMedia name="mail"/></a>
        <a href="#" className="db_facebook"><SocialMedia name="facebook"/></a>
        <a href="#" className="db_instagram"><SocialMedia name="instagram"/></a>
        <a href="#" className="db_linkedin"><SocialMedia name="linkedin"/></a>
        <a href="#" className="db_twiter"><SocialMedia name="twiter"/></a>
      </div>

     
      {/* offset map */}
      <div className="block offset_map">
        <div className="offset_map_img"><Map /></div>
      </div>
      
      {/* offset info */}
      <div className="block offset_info">
        <div className="offset_info_title">OUR PORTFOLIO</div>
        <div className="offset_info_text">
        At offsetgo, we curate a balanced portfolio of carbon offset projects from trusted 
        partners who rigorously verify the integrity of their carbon offsetting projects and 
        are actually avoiding, reducing, or removing the carbon emissions in the quantity that 
        they claim. We select projects based on the following three criteria: (1) Impact 
        potential over the next three decades (to 2050) as supported by Project Drawdown research, 
        (2) Net zero alignment prioritizing “carbon removal” over “emission reduction” and 
        “long-lived storage” as per the recommendations of the Oxford Principle for Net zero 
        aligned carbon offsetting, and (3) User preference.
        </div>
      </div>
      {/* offset credit */}
      <div className="green_block offset_credits">
        <div className="offset_credits_icon"><OffsetButton name="green"/></div>
        <div className="offset_credits_title offset">REMAINING<br/>CREDITS</div>
        <div className="offset_credits_value offset">- 0.60</div>
      </div>  
      {/* offset payment */}
      <div className="green_block offset_payment">
        {/* payment part 1 */}
        <div id="payment_part_1">
          <div className="offset_payment_1_title">BUY ADDITIONAL<br/>CARBON CREDITS</div>
          <label className="co2e_label co2e_button_label">How many Tons of CO2e?</label>
        
          <a href="#"className="one_ton"onClick={function() {setNbTon(1);}}><OneTon index={nbTon}/></a>
          <a href="#"className="two_ton"onClick={function() {setNbTon(2);}}><TwoTons index={nbTon}/></a>
          <a href="#"className="three_ton"onClick={function() {setNbTon(3);}}><ThreeTons index={nbTon}/></a>

          
          <label className="co2e_label co2e_input_label">Or enter a custom amount</label>
          <input type="number" min="0" step="0.01" className="form_input" id="co2e_input"/>
          <label className="co2e_label co2e_price_label">Price per Ton of CO2e</label>
          <label className="co2e_label co2e_price_value">$12.00</label>
          <div className="offset_line"></div>
          <label className="total_price_label">TOTAL</label>
          <label className="total_price_value">$56.00</label>
          <a href="#" className="checkout_button" onClick={function () {
            document.getElementById('payment_part_1').style.display ='none';
            document.getElementById('payment_part_2').style.display ='block';
          }}>Check out</a>
        </div>
        {/* payment part 2 */}
        <div id="payment_part_2" hidden>
          <div className="offset_payment_2_title">PAYMENT</div>
          <div className="payment_value">$60.00</div>
          <label className="payment_2_label card_name_label">CARDHOLDER NAME</label>
          <input type="text"  className="form_input"  id="card_name_input"/>
          <label className="payment_2_label card_number_label">CARD NUMBER</label>
          <input type="number" min="0"  className="form_input" id="card_number_input"/>
          <label className="payment_2_label expiry_date_label">EXPIRY DATE</label>
          <input type="text"  className="form_input"  id="expiry_date_input"/>
          <label className="payment_2_label cvc_label">CVC</label>
          <input type="text"  className="form_input"  id="cvc_input"/>
          <a href="#" className="checkout_button " onClick={function () {
            document.getElementById('payment_part_2').style.display ='none';
            document.getElementById('payment_part_3').style.display ='block';
          }}>Pay</a>
        </div>
        {/* payment part 2 */}
        <div id="payment_part_3" hidden>
          <div className="payment_3_title">Thank you!</div>
        </div>
      </div>
      
    </div>
  );

};


export default Track;



