import axios from 'axios';
import baseUrl from './baseUrl';
export default {
  track(url = baseUrl + 'track/') {
    return {
      addTrack : (id,schedule_json,travel_json,commute_json ,space_json,workspace_json ,digital_json, 
        purchases_json) => axios.post(url+'Add?id='+id+'&schedule_json='+schedule_json+'&travel_json='+travel_json+'&commute_json='+commute_json
        +'&space_json='+space_json+'&workspace_json='+workspace_json+'&digital_json='+digital_json
        +'&purchases_json='+purchases_json),
      trackList:()=>axios.get(url+'GetAll'),
      trackById:(id)=>axios.get(url+'GetById?id='+id), 
      deleteTrack:(id)=> axios.delete(url+'Delete?id='+id)
    };
  }
};
