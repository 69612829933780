import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import { useHistory } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './RegisterContainer.css';
import './Query.css';
import 'font-awesome/css/font-awesome.min.css';
import * as actions from '../../actions/employees';
import Hexagon from 'react-hexagon';
import VerifierRegister from './RegisterRequired';

const RegisterContainer = (props)=>{
  const history = useHistory();
  toast.configure();
  
  const onSuccess = (msj) => {
    toast.success(msj,{position:toast.POSITION.TOP_CENTER});
    history.push('/');  
  };
  const onfail = (msj) => {
    document.getElementById('register_block').style.display='block';
    document.getElementById('register_loading').style.display='none';
    toast.error(msj,{position:toast.POSITION.TOP_CENTER});
  };
    
  return (
    <div>
      <div id="register_block">
        <Hexagon  style={{stroke: '#55b79500',fill:'#55b79521'}}  className="login_hex_1" />
        <Hexagon  style={{stroke: '#55b79500',fill:'#55b79521'}}  className="login_hex_2" />
        <Hexagon  style={{stroke: '#55b79500',fill:'#55b79521'}}  className="login_hex_3" />
        <Hexagon  style={{stroke: '#55b79500',fill:'#55b79521'}}  className="login_hex_4" />
        <div className="title">
          <span>Work from Anywhere, Carbon Free</span>
        </div>
        <div className="text">
          <span>Track, reduce, and offset carbon emitted while working from home, in the office, in shared spaces, on the road…</span>
        </div>
        <div className="register_container">
          <div className="register_title">
            <span>SIGNUP</span>
          </div>
          
          <div>
            <label className="register_firstname_label">FIRST NAME</label>
            <input className="register_firstname_input" id="register_firstname" type="text" required  />
            <label className="register_lastname_label">LAST NAME</label>
            <input className="register_lastname_input" id="register_lastname" type="text" required/>
            <label className="register_email_label">EMAIL</label>
            <input className="register_email_input" id="register_email" type="email" required/>
            <label className="register_password_label">PASSWORD</label>
            <input className="register_password_input" id="register_password" type="password" required/>
            <a href="#" id="register_button" onClick={function () {
              var firstname = document.getElementById('register_firstname').value;
              var lastname = document.getElementById('register_lastname').value;
              var email = document.getElementById('register_email').value;
              var password = document.getElementById('register_password').value;
              var response = VerifierRegister(firstname,lastname,email,password);
              if(!response.res)
              {
                toast.error(response.msj,{position:toast.POSITION.TOP_CENTER});
              }
              else
              {
                document.getElementById('register_block').style.display='none';
                document.getElementById('register_loading').style.display='block';
                props.register(firstname , lastname , email,password,onSuccess,onfail);
              }
            }}>Signup</a>
          </div>
          <div className="login_redirection">
            <a href="/">Login</a>
          </div>
          <div className="terms_conditions">
            <a href="#">Terms and conditions and privacy policy</a>
          </div>
        </div>
      </div>
      <div id="register_loading" hidden><div className="track_menu_loader_blue"></div></div>
    </div>
  );
};

const mapStateToProps = state =>({
  response : state.employees.response
});
const mapActionToProps={
  register : actions.register 
};

RegisterContainer.propTypes = {
  register: PropTypes.func,
};
export default connect(mapStateToProps,mapActionToProps)(RegisterContainer);
