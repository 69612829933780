import './App.css';
import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Welcome from './containers/welcome/Welcome';
import LoginContainer from './containers/login/LoginContainer';
import RegisterContainer from './containers/register/RegisterContainer';
import PageNotFound from './containers/pageNotFound/PageNotFound';
import Settings from './containers/settings/Settings';
import Dashboard from './containers/dashboard/Dashboard';
import { store } from './actions/store';
import { Provider } from 'react-redux';
import {Container} from '@material-ui/core';
import Track from './containers/track/Track';
import Offset from './containers/offset/Offset'; 
import ApexChart from './components/chart/ApexChart';


class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Container maxWidth="lg">
          <Router className="App">
            <div>
              <Switch>
                <Route path="/welcome">
                  <Welcome />
                </Route>
                <Route path="/apex">
                  <ApexChart />
                </Route>
                <Route path="/dashboard">
                  <Dashboard />
                </Route>
                <Route path="/track">
                  <Track />
                </Route>
                <Route path="/offset">
                  <Offset />
                </Route>
                <Route path="/settings">
                  <Settings />
                </Route>
                <Route exact path="/">
                  <LoginContainer />
                </Route>
                <Route exact path="/register">
                  <RegisterContainer />
                </Route>
                <Route>
                  <PageNotFound />
                </Route>
              </Switch>
            </div>
          </Router>
        </Container>
      </Provider>
    );
  }
}

export default App;
