import { ACTION_TYPES } from '../actions/heatingFuelsUnits';
const initialState = {
  list: []
};
export const heatingFuelsUnits = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_HEATING_FUELS_UNITS: 
      return {
        ...state,
        list: [...action.payload] 
      };
    default:
      return state;
  }
};
