import React, { Component } from 'react';
import PropTypes from 'prop-types';

class WfButton extends Component {
  render() {
    const {
      name
    } = this.props;

    switch(name) {
      case 'black':
        return <img src={'/wf_black_button.png'} />;
      default:
        return <img src={'/wf_white_button.png'} />;
    }  
  }
}

WfButton.propTypes = {
  name : PropTypes.string.isRequired
};


export default WfButton;
