import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Projects extends Component {
  render() {
    const {
      index
    } = this.props;

    switch(index)
    {
      case 1:
        return <div className="project">
          <img src={'/projects/project_1.png'} />
        </div>;
      case 2 : 
        return <div className="project">
          <img src={'/projects/project_2.png'} />
        </div>;
      case 3 : 
        return <div className="project">
          <img src={'/projects/project_3.png'} />
        </div>;
    }
  }
}

Projects.propTypes = {
  index : PropTypes.number.isRequired
};


export default Projects;
