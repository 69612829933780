import React, { Component } from 'react';
import PropTypes from 'prop-types';

class NavigationButton extends Component {
  render() {
    const {
      name
    } = this.props;

    switch(name)
    {
      case 'next_blue':
        return <img src={'/next_blue_button.png'} />;  
      case 'previous_blue':
        return <img src={'/previous_blue_button.png'} />;
      case 'next_green':
        return <img src={'/next_green_button.png'} />;  
      case 'previous_green':
        return <img src={'/previous_green_button.png'} />;
      case 'save_blue':
        return <img src={'/save_blue_button.png'} />;
      case 'save_green':
        return <img src={'/save_green_button.png'} />;
      case 'down':
        return <img src={'/arrow_down.png'} />;
      case 'down_blue':
        return <img src={'/arrow_down_blue.png'} />;
      case 'down_green':
        return <img src={'/arrow_down_green.png'} />;
    }

  }
}

NavigationButton.propTypes = {
  name : PropTypes.string.isRequired
};


export default NavigationButton;
