import { ACTION_TYPES } from '../actions/settings';
const initialState = {
  List:[],
  updateEmployeesRes:false,
  updateOrganizationSubRes:false,
};
export const settings = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.ALL:
      return {
        ...state,
        list: [...action.payload]
      }; 
    case ACTION_TYPES.UPDATE_EMPLOYEES:
      return {
        ...state,
        updateEmployeesRes: [...action.payload]
      };
    case ACTION_TYPES.UPDATE_ORGANIZATION_SUB:
      return {
        ...state,
        updateOrganizationSubRes: [...action.payload]
      };
    default:
      return state; 
  }
};
