import axios from 'axios';
import baseUrl from './baseUrl';
export default {
  employees(url = baseUrl + 'employees/') {
    return {
      login : (email, password) => axios.get(url+'login?Email='+email+'&Password='+password),
      register : (firstname,lastname,email,password)=> axios.get(url+'register?firstname='+firstname+'&lastname='+lastname+'&email='+email+'&password='+password),
      userConnected :()=> axios.get(baseUrl+'users/GetConnectedUser')
    };
  }
};
