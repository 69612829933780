import React, { Component } from 'react';
import PropTypes from 'prop-types';

class OneTon extends Component {
  render() {
    const {
      index
    } = this.props;

    switch(index) {
      case 1:
        return <img src={'/1ton_clicked.png'} />;
      default:
        return <img src={'/1ton.png'} />;
    }  
  }
}

OneTon.propTypes = {
  index : PropTypes.number.isRequired
};


export default OneTon;
