import './Track.css';
import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import TrackButton from '../../components/Icons/TrackButton';
import InfoButton from '../../components/Icons/InfoButton';
import WfButton from '../../components/Icons/WfButton';
import OffsetButton from '../../components/Icons/OffsetButton';
import SettingButton from '../../components/Icons/SettingButton';
import SocialMedia from '../../components/Icons/SocialMedia';
import AccountButton from '../../components/Icons/AccountButton';
import NotificationButton from '../../components/Icons/NotificationButton';
import Addbutton from '../../components/Icons/Addbutton';
import Sections from '../../components/Icons/Sections';
import NavigationButton from '../../components/Icons/NavigationButton';
import TrackForm from './TrackForm';
import * as track_actions from '../../actions/track';
import DeleteButton from '../../components/Icons/DeleteButton';
import {toast} from 'react-toastify';



const Track = (props)=> {
  const [index, setIndex] = useState({
    count:1,
    id:0
  });

  const [modal, setModal] = useState({
    open:false,
    title:'',
    id:0
  });

  useEffect(() => {
    props.fetchAllTrack();
  }, []);

  var top_value = 4;


  function sleep (time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
  
  var onSuccess = () => {
    location.reload();  
  };
  var onfail = () => {
    toast.error('Fail',{position:toast.POSITION.TOP_CENTER});
  };

  if(props.trackList == undefined)
  {
    return <div>
      {/* account notification */}

      <a href="#" className="account_button"><AccountButton /></a>
      <a href="#" className="notification_button"><NotificationButton /></a>

      {/* left menu */}
        
      <div className="left_menu_block">
        <a href="./dashboard" className="wf_white_button"><WfButton name="white"/><br/>DASHBOARD</a> 
        <a href="./track" className="track_black_button"><TrackButton name="black"/><br/>TRACK</a>
        <a href="#" className="info_button"><InfoButton/><br/>LEARN</a>
        <a href="./offset" className="offset_white_button"><OffsetButton name="white"/><br/>OFFSET</a>
        <a href="./settings" className="setting_white_button"><SettingButton name="white"/><br/>SETTINGS</a>
      </div>
      <div className="social_media">
        <a href="#" className="db_mail"><SocialMedia name="mail"/></a>
        <a href="#" className="db_facebook"><SocialMedia name="facebook"/></a>
        <a href="#" className="db_instagram"><SocialMedia name="instagram"/></a>
        <a href="#" className="db_linkedin"><SocialMedia name="linkedin"/></a>
        <a href="#" className="db_twiter"><SocialMedia name="twiter"/></a>
      </div>
      <div className="track_menu_loader_blue"></div>
    </div>;
  }
  else
  {
    return (
      <div>
        {/* account notification */}

        <a href="#" className="account_button"><AccountButton /></a>
        <a href="#" className="notification_button"><NotificationButton /></a>


        {/* left menu */}
        
        <div className="left_menu_block">
          <a href="./dashboard" className="wf_white_button"><WfButton name="white"/><br/>DASHBOARD</a> 
          <a href="./track" className="track_black_button"><TrackButton name="black"/><br/>TRACK</a>
          <a href="#" className="info_button"><InfoButton/><br/>LEARN</a>
          <a href="./offset" className="offset_white_button"><OffsetButton name="white"/><br/>OFFSET</a>
          <a href="./settings" className="setting_white_button"><SettingButton name="white"/><br/>SETTINGS</a>
        </div>
        <div className="social_media">
          <a href="#" className="db_mail"><SocialMedia name="mail"/></a>
          <a href="#" className="db_facebook"><SocialMedia name="facebook"/></a>
          <a href="#" className="db_instagram"><SocialMedia name="instagram"/></a>
          <a href="#" className="db_linkedin"><SocialMedia name="linkedin"/></a>
          <a href="#" className="db_twiter"><SocialMedia name="twiter"/></a>
        </div>
        <div id="track_block">
          {/* Track menu */}
          <div className="track_menu block">
            <a href="#" id="add_track" className="add_new_track" title="Add a new work setup" onClick={function() {
              setIndex({count:1,id:0});
              document.getElementById('track_info').style.display ='none';
              document.getElementById('track_form').style.display ='block';
              document.getElementById('next').style.display = 'block';
              document.getElementById('previous').style.display = 'none';
              document.getElementById('finish').style.display = 'none';
            }}><Addbutton name="blue" /></a>
            <div className="track_menu_title">TRACK EMISSIONS</div>
            {props.trackList.map((track)=>{
              top_value = top_value +11;
              return <div className="track_setup" key={track.id} style={{top: top_value+'%'}}>
                <a href="#" className="track_workspace"  onClick={function() {
                  document.getElementById('add_track').click();
                  sleep(50).then(() => {
                    setIndex({count:1,id:track.id});
                  });
                }}>
                  <div className="track_workspace_title">{track.setup_name.toUpperCase()}</div>
                </a>
                <a href="#" className="delete_button" onClick={function() {
                  setModal({open:true,title:track.setup_name.toUpperCase(), id: track.id});
                }}><DeleteButton/></a>
              </div>;
            })}
          </div>
          {/* Track form */}
          <div className="track_form_info block">
            <div id="track_info"></div>
            <div id="track_form" hidden>
              <div className="track_menu_icons">
                <Sections  index={index.count}></Sections>
              </div>
              <TrackForm index={index}/>
              <a className="track_next_button" id="next" href="#" onClick={function next_function() {
                if(index.count !=12)  
                  setIndex({...index, count:index.count +1});
                if(index.count>0)
                  document.getElementById('previous').style.display = 'block';
                if(index.count == 11)
                {
                  document.getElementById('next').style.display = 'none';
                  document.getElementById('finish').style.display = 'block';
                }
              }}><NavigationButton name="next_blue"/></a>
              <a className="track_previous_button" id="previous" hidden href="#"onClick={function previous_function() {
                if(index.count !=1)
                  setIndex({...index, count:index.count -1});
                if(index.count<13)
                {
                  document.getElementById('finish').style.display = 'none';
                  document.getElementById('next').style.display = 'block';
                }
                if(index.count == 2)
                  document.getElementById('previous').style.display = 'none';
              }}><NavigationButton name="previous_blue"/></a>
              <a className="track_finish_button" id="finish" hidden href="#" onClick={function finish_function() {
                document.getElementById('finish').style.display ='none';
                document.getElementById('previous').style.display ='none';
                setIndex({...index, count:index.count +1});
              }}><NavigationButton name="save_blue"/></a>  
            </div>
          </div>
        </div>
        <div id="track_loading" hidden><div className="track_menu_loader_blue"></div></div>
        <a href="#" id="chek_error" hidden onClick={function () {
          if(index.count == 11)
          {
            document.getElementById('next').style.display = 'block';
            document.getElementById('finish').style.display = 'none';
          }
          if(index.count == 12)
          {
            document.getElementById('previous').style.display = 'block';
            document.getElementById('finish').style.display = 'block';
          }
          setIndex({...index,count:index.count});
        }}></a>
        {/*************************** Delete Modal **************************** */}
        <div className="modalBackground" hidden style={{display:modal.open?'block':'none'}}>
          <div className="modalContainer">
            <div className="titleCloseBtn">
              <button
                onClick={() => {
                  setModal({...modal,open:false});
                }}
              >
              X
              </button>
            </div>
            <div className="modal_title">
              <h1>Are You Sure You Want to Delete <br/>{modal.title} ?</h1>
            </div>
            <div className="footer">
              <button
                onClick={() => {
                  setModal({...modal,open:false});
                }}
                id="cancelBtn"
              >
                Cancel
              </button>
              <button
                onClick={()=>{
                  setModal({...modal,open:false});
                  document.getElementById('track_block').style.display ='none';
                  document.getElementById('track_loading').style.display='block';
                  props.DeleteTrack(modal.id,onSuccess,onfail); 
                }}
              >Delete</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

};


const mapStateToProps = state => ({
  trackList: state.track.list

});

const mapActionToProps = {

  fetchAllTrack: track_actions.trackList,
  DeleteTrack: track_actions.deleteTrack
};

Track.propTypes = {
  fetchAllTrack: PropTypes.func,
  trackList: PropTypes.array,
  DeleteTrack: PropTypes.func,
};

export default connect(mapStateToProps,mapActionToProps)(Track);



