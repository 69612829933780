import { ACTION_TYPES } from '../actions/selectItems';
const initialState = {
  list: [],
  airportsFromList :[],
  airportsToList :[]
};
export const selectItems = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ALL_SELECT_ITEMS: 
      return {
        ...state,
        list: [...action.payload] 
      };
    default:
      return state;
  }
};
