import api from '../api/settings';
export const ACTION_TYPES={
  ALL:'ALL',
  UPDATE_EMPLOYEES:'UPDATE_EMPLOYEES',
  UPDATE_ORGANIZATION_SUB:'UPDATE_ORGANIZATION_SUB'
};

export const settingsList = () => dispatch => {
  api.settings().settingsList()
    .then(res => {
      dispatch({
        type: ACTION_TYPES.ALL,
        payload: res.data
      });
    })
    .catch(err => console.log(err)); 
};

export const UpdateEmployees = (employee_json,onSuccess,onFail) => dispatch => {
  
  api.settings().updateEmployees(employee_json) 
    .then(res => {
      if(res.data.success)
      {
        onSuccess();
      }
      else
      {
        onFail();
      }
      dispatch({
        type: ACTION_TYPES.UPDATE_EMPLOYEES,
        payload: res.data.success
      });
    })
    .catch(err => console.log(err));
};

export const updateOrganizationSub = (organization_sub_json,onSuccess,onFail) => dispatch => {
  
  api.settings().updateOrganizationSub(organization_sub_json) 
    .then(res => {
      if(res.data.success)
      {
        onSuccess();
      }
      else
      {
        onFail();
      }
      dispatch({
        type: ACTION_TYPES.UPDATE_ORGANIZATION_SUB,
        payload: res.data.success
      });
    })
    .catch(err => console.log(err));
};



