import { ACTION_TYPES } from '../actions/dashboard';
const initialState = {
  list:[]
};
export const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.EMP_TRACK:
      return {
        ...state,
        list: [...action.payload]
      };
    default:
      return state;
  }
};
