import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Sections extends Component {
  render() {
    const {
      index
    } = this.props;

    switch(index) {
      case 1:
        return<div>
          <img className="section_img" src={'/schedule_clicked_menu.png'} />
          <img className="section_img" src={'/travel_menu.png'} />
          <img className="section_img" src={'/commute_menu.png'} />
          <img className="section_img" src={'/home_menu.png'} />
          <img className="section_img" src={'/workspace_menu.png'} />
          <img className="section_img" src={'/digital_menu.png'} />
          <img className="section_img" src={'/purchases_menu.png'} />
        </div>;
      case 2:
        return<div>
          <img className="section_img" src={'/schedule_menu.png'} />
          <img className="section_img" src={'/travel_clicked_menu.png'} />
          <img className="section_img" src={'/commute_menu.png'} />
          <img className="section_img" src={'/home_menu.png'} />
          <img className="section_img" src={'/workspace_menu.png'} />
          <img className="section_img" src={'/digital_menu.png'} />
          <img className="section_img" src={'/purchases_menu.png'} />
        </div>;
      case 3:
        return<div>
          <img className="section_img" src={'/schedule_menu.png'} />
          <img className="section_img" src={'/travel_menu.png'} />
          <img className="section_img" src={'/commute_clicked_menu.png'} />
          <img className="section_img" src={'/home_menu.png'} />
          <img className="section_img" src={'/workspace_menu.png'} />
          <img className="section_img" src={'/digital_menu.png'} />
          <img className="section_img" src={'/purchases_menu.png'} />
        </div>;
      case 4:
      case 5:
      case 6:
        return<div>
          <img className="section_img" src={'/schedule_menu.png'} />
          <img className="section_img" src={'/travel_menu.png'} />
          <img className="section_img" src={'/commute_menu.png'} />
          <img className="section_img" src={'/home_clicked_menu.png'} />
          <img className="section_img" src={'/workspace_menu.png'} />
          <img className="section_img" src={'/digital_menu.png'} />
          <img className="section_img" src={'/purchases_menu.png'} />
        </div>;
      case 7:
      case 8:
        return<div>
          <img className="section_img" src={'/schedule_menu.png'} />
          <img className="section_img" src={'/travel_menu.png'} />
          <img className="section_img" src={'/commute_menu.png'} />
          <img className="section_img" src={'/home_menu.png'} />
          <img className="section_img" src={'/workspace_clicked_menu.png'} />
          <img className="section_img" src={'/digital_menu.png'} />
          <img className="section_img" src={'/purchases_menu.png'} />
        </div>;
      case 9:
      case 10:
        return<div>
          <img className="section_img" src={'/schedule_menu.png'} />
          <img className="section_img" src={'/travel_menu.png'} />
          <img className="section_img" src={'/commute_menu.png'} />
          <img className="section_img" src={'/home_menu.png'} />
          <img className="section_img" src={'/workspace_menu.png'} />
          <img className="section_img" src={'/digital_clicked_menu.png'} />
          <img className="section_img" src={'/purchases_menu.png'} />
        </div>;
      case 11:
      case 12:
        return<div>
          <img className="section_img" src={'/schedule_menu.png'} />
          <img className="section_img" src={'/travel_menu.png'} />
          <img className="section_img" src={'/commute_menu.png'} />
          <img className="section_img" src={'/home_menu.png'} />
          <img className="section_img" src={'/workspace_menu.png'} />
          <img className="section_img" src={'/digital_menu.png'} />
          <img className="section_img" src={'/purchases_clicked_menu.png'} />
        </div>;
      default:
        return<div>
          <img className="section_img" src={'/schedule_menu.png'} />
          <img className="section_img" src={'/travel_menu.png'} />
          <img className="section_img" src={'/commute_menu.png'} />
          <img className="section_img" src={'/home_menu.png'} />
          <img className="section_img" src={'/workspace_menu.png'} />
          <img className="section_img" src={'/digital_menu.png'} />
          <img className="section_img" src={'/purchases_menu.png'} />
        </div>;
    }  
  }
}

Sections.propTypes = {
  index : PropTypes.number.isRequired,
};


export default Sections;
