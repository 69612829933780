import axios from 'axios';
import baseUrl from './baseUrl';

export default {
  dashboard(url = baseUrl + 'dashboard/') {
    return {
      employeeTrack:()=> axios.get(url+'employeeTrack')
    };
  }
};
