import React, { Component } from 'react';
import Chart from 'react-apexcharts';
class ApexChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
    
      series: [{
        name: 'TRAVEL',
        data: [44, 55, 41, 37, 22]
      },{
        name: 'WORKSPACE',
        data: [12, 17, 11, 9, 15]
      }, {
        name: 'DIGITAL USE',
        data: [9, 7, 5, 8, 6]
      }, {
        name: 'PURCHASES',
        data: [25, 12, 19, 32, 25]
      }],
      options: {
        colors: ['#1DB5C9', '#49BDCC', '#67D9E8', '#B9F5FD'],
        chart: {
          type: 'bar',
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ['#000']
        },
        xaxis: {
          categories: ['HOME', 'OFFICE', 'SHARED SPACES', 'ROAD', 'ELSEWHERE'],
          labels: {
            style:{
              colors:['#fff','#fff','#fff','#fff','#fff']
            }
            
          }
        },
        yaxis: {
          labels: {
            style:{
              colors:['#fff','#fff','#fff','#fff','#fff']
            }
          }
        },
        dataLabels:{
          style: {
            colors: ['#000', '#000', '#000', '#000']
          },
        },
        tooltip: {
          enabled:true
        },
        legend: {
          show:false
        }
      },
    
    
    };
  }



  render() {
    return (
      

      <div id="chart" style={{marginTop:200}}>
        <Chart options={this.state.options} series={this.state.series} type="bar" height={350} />
      </div>
    );
  }
}
export default ApexChart;
