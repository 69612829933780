import './Welcome.css';
import React  from 'react';
/*import {connect} from 'react-redux';
import * as actions from '../../actions/employees';
import PropTypes from 'prop-types';
import Navigationbutton from '../../components/Icons/NavigationButton';*/
import Hexagon from 'react-hexagon';
import AccountButton from '../../components/Icons/AccountButton';
import NotificationButton from '../../components/Icons/NotificationButton';
import WelcomeIcons from '../../components/Icons/WelcomeIcons';
import NavigationButton from '../../components/Icons/NavigationButton';
import AddButton from '../../components/Icons/Addbutton';

const Welcome = (/*props*/)=> {
  /*useEffect(() => {
    props.userConnected();
  }, []);

  console.log(props);

  var firstname ='';
  var organization_name ='';
  var year = '';
  var carbon_offset ='';

  if(props.user[0]!= null)
  {
    firstname = props.user[0].firstname.toUpperCase();
    organization_name = props.user[0].organization_name.toUpperCase();
    year = props.user[0].year;
    if(props.user[0].carbon_offset_allowance > 1)
    {
      carbon_offset = props.user[0].carbon_offset_allowance +' tons';
    }
    else
    {
      carbon_offset = props.user[0].carbon_offset_allowance +' ton';
    }
  }

  if(props.user.length == 0)
  {
    return <div className="loader"></div>;
  }
  else
  {*/
  return (
    <div>
      <a href="#" className="account_button"><AccountButton /></a>
      <a href="#" className="notification_button"><NotificationButton /></a>
      <div id="welcome_page_1">
        <Hexagon  style={{stroke: '#55b79500',fill:'#40b6a217'}}  className="welcome_pg1_hex_1" />
        <Hexagon  style={{stroke: '#55b79500',fill:'#40b6a217'}}  className="welcome_pg1_hex_2" />
        <Hexagon  style={{stroke: '#55b79500',fill:'#40b6a217'}}  className="welcome_pg1_hex_3" />
        <div className="welcome_section">
          <div className="welcome_img_1"><WelcomeIcons index={1} /></div>
          <div className="welcome_txt txt_1_1">Every day<br/> you work,</div>
          <div className="welcome_txt txt_1_2">whether it is at home, in<br/>the office, in a shared 
            <br/>space, on the road, <br/>etc…,
          </div>
        </div>
        <div className="welcome_section">
          <div className="welcome_img_2"><WelcomeIcons index={2} /></div>
          <div className="welcome_txt txt_2">you emit carbon from<br/>travel, occupying a<br/>
            workspace, digital use,<br/>and work purchases.</div>
        </div>
        <div className="welcome_section_3">
          <div className="welcome_img_3"><WelcomeIcons index={3} /></div>
          <div className="welcome_txt txt_3">Offsetgo helps you and<br/>your organization track<br/>
            and manage these<br/>emissions overtime.</div>
        </div>
        <a href="#" className="get_started" onClick={function() {
          document.getElementById('welcome_page_1').style.display='none';
          document.getElementById('welcome_page_2').style.display='block';
        }}>GET STARTED<br/><NavigationButton name="down"/></a>
      </div>
      <div id="welcome_page_2" hidden>
        <a href="#" className="new_organization"><AddButton name="green" /></a>
        <Hexagon  style={{stroke: '#55b79500'}} flatTop={true} backgroundImage="/bg_degrade.png" href="./dashboard"  className="welcome_hex pg2_hex_1"/>
        <Hexagon  style={{stroke: '#55b79500',fill:'#40b6a217'}} flatTop={true}  className="welcome_hex pg2_hex_2"/>
        <Hexagon  style={{stroke: '#55b79500',fill:'#40b6a217'}} flatTop={true}  className="welcome_hex pg2_hex_3"/>
        <Hexagon  style={{stroke: '#55b79500',fill:'#40b6a217'}} flatTop={true}  className="welcome_hex pg2_hex_4"/>
        <Hexagon  style={{stroke: '#55b79500',fill:'#40b6a217'}} flatTop={true}  className="welcome_hex pg2_hex_5"/>
        <Hexagon  style={{stroke: '#55b79500',fill:'#40b6a217'}} flatTop={true}  className="welcome_hex pg2_hex_6"/>
        <Hexagon  style={{stroke: '#55b79500',fill:'#40b6a217'}} flatTop={true}  className="welcome_hex pg2_hex_7" href="#"/>
        <Hexagon  style={{stroke: '#55b79500',fill:'#40b6a217'}} flatTop={true}  className="welcome_hex pg2_hex_8"/>
        <Hexagon  style={{stroke: '#55b79500',fill:'#40b6a217'}} flatTop={true}  className="welcome_hex pg2_hex_9"/>
        <Hexagon  style={{stroke: '#55b79500',fill:'#40b6a217'}} flatTop={true}  className="welcome_hex pg2_hex_10"/>
        <Hexagon  style={{stroke: '#55b79500',fill:'#40b6a217'}} flatTop={true}  className="welcome_hex pg2_hex_11"/>
        <Hexagon  style={{stroke: '#55b79500',fill:'#40b6a217'}} flatTop={true}  className="welcome_hex pg2_hex_12"/>

      </div>
    </div>  
  );
  /*}*/
};

/*const mapStateToProps = state => ({
  user: state.employees.list
});

const mapActionToProps = {
  userConnected: actions.userConnected
};

Welcome.propTypes = {
  userConnected: PropTypes.func,
  user: PropTypes.array,
};*/

export default Welcome;



