import './Dashboard.css';
import React ,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import TrackButton from '../../components/Icons/TrackButton';
import InfoButton from '../../components/Icons/InfoButton';
import WfButton from '../../components/Icons/WfButton';
import OffsetButton from '../../components/Icons/OffsetButton';
import SettingButton from '../../components/Icons/SettingButton';
import { Bar } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import NavigationButton from '../../components/Icons/NavigationButton';
import Projects from '../../components/containers/projects/Projects';
import SocialMedia from '../../components/Icons/SocialMedia';
import AccountButton from '../../components/Icons/AccountButton';
import NotificationButton from '../../components/Icons/NotificationButton';
import * as dashboard_actions from '../../actions/dashboard';
import Chart from 'react-apexcharts';

const Dashboard = (props)=> {

  const [count, setCounter] = useState(1);

  useEffect(() => {
    props.employeeTrack();
  }, []);
  
  var categories_data ={}; 
  var credit_data ={};
  var dest_data ={};
  var workdays_data ={};
  var ghg_data = {}; 
  var ghg_options ={}; 
  var per_employee_data ={};
  var per_employee_options ={};

  if(props.employee_track_List.length !=0)
  {

    /********************* categories data **************/
    categories_data = {
      series: [props.employee_track_List[0].track.travel_em, 
        props.employee_track_List[0].track.commute_em, 
        props.employee_track_List[0].track.workspace_em,
        props.employee_track_List[0].track.digital_em,
        props.employee_track_List[0].track.purchases_em],
      options: {
        colors: ['#138898','#1AA7BA', '#49BDCC', '#67D9E8', '#B9F5FD'],
        chart: {
          type: 'donut', 
        },
        stroke:{
          colors:['#000']
        },
        labels: ['TRAVEL','COMMUTE', 'WORKSPACE', 'DIGITAL USE', 'PURCHASES'],
        legend:{
          show:false
        },
        dataLabels:{
          style: {
            colors: ['#000', '#000','#000', '#000', '#000']
          },
        },
        tooltip: {
          fillSeriesColor: false,
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'left'
            }
          }
        }]
      },      
    };

    
    /********************* credit data **************/
    credit_data = {
      datasets: [
        {
          data: [45, 10, 25,20],
          backgroundColor: [
            '#6BAC6A',
            '#74BB72',
            '#A0DE9E',
            '#BFEFBE',
          ],
          borderWidth: 0.2,
          borderColor:'#000000',
        },
      ],
    };

    /************************ destribution ******************************* */
    dest_data = {
      series: [{
        name: 'TRAVEL',
        data: [props.employee_track_List[0].home.travel_em,
          props.employee_track_List[0].office.travel_em,
          props.employee_track_List[0].shared_spaces.travel_em,
          props.employee_track_List[0].road.travel_em,
          props.employee_track_List[0].other.travel_em]
      },{
        name: 'COMMUTE',
        data: [props.employee_track_List[0].home.commute_em,
          props.employee_track_List[0].office.commute_em,
          props.employee_track_List[0].shared_spaces.commute_em,
          props.employee_track_List[0].road.commute_em,
          props.employee_track_List[0].other.commute_em]
      },{
        name: 'WORKSPACE',
        data: [props.employee_track_List[0].home.workspace_em,
          props.employee_track_List[0].office.workspace_em,
          props.employee_track_List[0].shared_spaces.workspace_em,
          props.employee_track_List[0].road.workspace_em,
          props.employee_track_List[0].other.workspace_em]
      }, {
        name: 'DIGITAL USE',
        data: [props.employee_track_List[0].home.digital_em,
          props.employee_track_List[0].office.digital_em,
          props.employee_track_List[0].shared_spaces.digital_em,
          props.employee_track_List[0].road.digital_em,
          props.employee_track_List[0].other.digital_em]
      }, {
        name: 'PURCHASES',
        data: [props.employee_track_List[0].home.purchases_em,
          props.employee_track_List[0].office.purchases_em,
          props.employee_track_List[0].shared_spaces.purchases_em,
          props.employee_track_List[0].road.purchases_em,
          props.employee_track_List[0].other.purchases_em]
      }],
      options: {
        colors: ['#138898','#1AA7BA', '#49BDCC', '#67D9E8', '#B9F5FD'],
        chart: {
          type: 'bar',
          stacked: true,
          toolbar:{
            show : false,
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ['#000']
        },
        xaxis: {
          categories: ['HOME', 'OFFICE', 'SHARED SPACES', 'ROAD', 'ELSEWHERE'],
          labels: {
            style:{
              colors:['#fff','#fff','#fff','#fff','#fff']
            }
          },
        },
        yaxis: {
          labels: {
            style:{
              colors:['#fff','#fff','#fff','#fff','#fff']
            }
          },
        },
        dataLabels:{
          enabled:false,
        },
        tooltip: {
          enabled:true
        },
        legend: {
          show:false
        }
      },
    };
    
    /***************************************** workdays ************************************ */
    workdays_data = {
      datasets: [
        {
          data: [220, 90, 260],
          backgroundColor: [
            '#1CB3C6',
            '#74BB72',
            '#52B797',
          ],
          borderWidth: 1, 
        },
      ],
      
    };

    /************************ GHG ******************************* */
    ghg_data = {
      labels: ['SCOPE 1', 'SCOPE 2', 'SCOPE 3'],
      datasets: [
        {
          data: [18, 4, 13],
          backgroundColor: [
            '#1DB5C9',
            '#1DB5C9',
            '#1DB5C9',
          ],
          borderWidth: 1,
        },
      ],
    };
    
    ghg_options = {
      
      indexAxis: 'x',
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display:false,
        },
        title: {
          display: false,
        },
      },
    };

    /************************ per_employee ******************************* */
    per_employee_data = {
      labels: ['RYM B.', 'ZACK T.', 'AHMED K.','MEHDI B.'],
      datasets: [
        {
          data: [0.9, 0.5,0.3,0.1 ],
          backgroundColor: [
            '#1DB5C9',
            '#1DB5C9',
            '#1DB5C9',
            '#1DB5C9',
          ],
          borderWidth: 1,
        },
      ],
    };

    per_employee_options = {
      
      indexAxis: 'y',
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display:false,
        },
        title: {
          display: false,
        },
      },
    };
  }
  if(props.employee_track_List.length ==0)
  {

    return (
      <div>
        {/* account notification */}

        <a href="#" className="account_button"><AccountButton /></a>
        <a href="#" className="notification_button"><NotificationButton /></a>

        {/* left menu */}
        
        <div className="left_menu_block">
          <a href="./dashboard" className="wf_black_button"><WfButton name="black"/><br/>DASHBOARD</a> 
          <a href="./track" className="track_white_button"><TrackButton name="white"/><br/>TRACK</a>
          <a href="#" className="info_button"><InfoButton/><br/>LEARN</a>
          <a href="./offset" className="offset_white_button"><OffsetButton name="white"/><br/>OFFSET</a>
          <a href="./settings" className="setting_white_button"><SettingButton name="white"/><br/>SETTINGS</a>
        </div>
        <div className="social_media">
          <a href="#" className="db_mail"><SocialMedia name="mail"/></a>
          <a href="#" className="db_facebook"><SocialMedia name="facebook"/></a>
          <a href="#" className="db_instagram"><SocialMedia name="instagram"/></a>
          <a href="#" className="db_linkedin"><SocialMedia name="linkedin"/></a>
          <a href="#" className="db_twiter"><SocialMedia name="twiter"/></a>
        </div>
        <div className="track_menu_loader_blue"></div>
      </div>
    );
  }
  else
  {
    return (
      <div>
        {/* account notification */}

        <a href="#" className="account_button"><AccountButton /></a>
        <a href="#" className="notification_button"><NotificationButton /></a>

        {/* navigation buttons */}

        <a href="#" id="employee_dashboard_button" onClick={function() {
          document.getElementById('employee_dashboard_button').style.color='#ffffff';
          document.getElementById('employee_dashboard_button').style.textDecoration='underline';
          document.getElementById('organization_dashboard_button').style.color='#707070';
          document.getElementById('organization_dashboard_button').style.textDecoration='none';
          document.getElementById('organization_dashboard').style.display='none';
          document.getElementById('employee_dashboard').style.display='block';
        }}>YOU</a>
        <a href="#" id="organization_dashboard_button" onClick={function() {
          document.getElementById('employee_dashboard_button').style.color='#707070';
          document.getElementById('employee_dashboard_button').style.textDecoration='none';
          document.getElementById('organization_dashboard_button').style.color='#ffffff';
          document.getElementById('organization_dashboard_button').style.textDecoration='underline';
          document.getElementById('organization_dashboard').style.display='block';
          document.getElementById('employee_dashboard').style.display='none';
        }}>YOUR TEAM</a>

        {/* left menu */}
        
        <div className="left_menu_block">
          <a href="./dashboard" className="wf_black_button"><WfButton name="black"/><br/>DASHBOARD</a> 
          <a href="./track" className="track_white_button"><TrackButton name="white"/><br/>TRACK</a>
          <a href="#" className="info_button"><InfoButton/><br/>LEARN</a>
          <a href="./offset" className="offset_white_button"><OffsetButton name="white"/><br/>OFFSET</a>
          <a href="./settings" className="setting_white_button"><SettingButton name="white"/><br/>SETTINGS</a>
        </div>
        <div className="social_media">
          <a href="#" className="db_mail"><SocialMedia name="mail"/></a>
          <a href="#" className="db_facebook"><SocialMedia name="facebook"/></a>
          <a href="#" className="db_instagram"><SocialMedia name="instagram"/></a>
          <a href="#" className="db_linkedin"><SocialMedia name="linkedin"/></a>
          <a href="#" className="db_twiter"><SocialMedia name="twiter"/></a>
        </div>

        {/* employee dashboard section */}
        <div id="employee_dashboard">
          
          <div className="tracked_block block">
            <div className="tracked_icon"><TrackButton name="blue"/></div>
            <div className="tracked_title tracked">EMISSIONS<br/>TRACKED</div>
            <div className="tracked_value tracked">+ {props.employee_track_List[0].track.total_em}</div>
            <div className="tracked_unit tracked">tons of CO2e</div>
          </div>
          <div className="offset_block block">
            <div className="offset_icon"><OffsetButton name="green"/></div>
            <div className="offset_title offset">EMISSIONS<br/>REMOVED</div>
            <div className="offset_value offset">- 2.00</div>
            <div className="offset_unit offset">tons of CO2e</div>
          </div>
          <div className="workdays_block block">
            <div className="workdays_title">WORKDAYS IN 2020</div>
            <div className="workdays_subtitle">/365 days</div>
            <div className="doughnut_chart"><Doughnut data={workdays_data}/></div>
            <div className="workday_worked">WORKED<br/>(260 DAYS)</div>
            <div className="workday_tracked">TRACKED<br/>(240 DAYS)</div>
            <div className="workday_offset">OFFSET<br/>(90 DAYS)</div>
          </div>
          <div className="categories_block block">
            <div className="categories_title tracked">PER EMISSION<br/>CATEGORIES</div>
            <div className="donut_categ_chart">
              <Chart options={categories_data.options} series={categories_data.series} type="donut" />
            </div>
            <div className="legend_block first first_tracked"></div>
            <div className="legend_title first_title">TRAVEL</div>
            <div className="legend_block second second_tracked"></div>
            <div className="legend_title second_title">COMMUTE</div>
            <div className="legend_block third third_tracked"></div>
            <div className="legend_title third_title">WORKSPACE</div>
            <div className="legend_block fourth fourth_tracked"></div>
            <div className="legend_title fourth_title">DIGITAL USE</div>
            <div className="legend_block fifth fifth_tracked"></div>
            <div className="legend_title fifth_title">PURCHASES</div>
          </div>
          <div className="credit_block block">
            <div className="credit_title offset">BY CREDIT<br/>TYPES</div>
            <div className="pie_chart"><Doughnut data={credit_data}/></div>
            <div className="legend_block second first_offset"></div>
            <div className="legend_title second_title">FORESTRY</div>
            <div className="legend_block third second_offset"></div>
            <div className="legend_title third_title">OCEAN</div>
            <div className="legend_block fourth third_offset"></div>
            <div className="legend_title fourth_title">COMMUNITY</div>
            <div className="legend_block fifth fourth_offset"></div>
            <div className="legend_title fifth_title">RENEWABLES</div>
          </div>
          <div className="distribution_block block">
            <div className="distribution_title tracked">PER WORK SETUP</div>
            <div className="distribution_subtitle tracked">(TONS OF CO2e)</div>
            <div className="bar_chart">
              <Chart options={dest_data.options} series={dest_data.series} type="bar" height={220}/>
            </div>
          </div>
          <div className="projects_block block">
            <div className="projects_title offset">CARBON REMOVAL PROJECTS</div>
            <Projects index={count} />
            <a className="projects_next" href="#" onClick={function next_function() {
              if(count == 3)
                setCounter(1);
              else
                setCounter(count +1);
            }}><NavigationButton name="next_green"/></a>
            <a className="projects_previous" href="#" onClick={function previous_function() {
              if(count == 1)
                setCounter(3);
              else
                setCounter(count -1);
            }}><NavigationButton name="previous_green"/></a>
          </div>
        
        </div>
        {/* organization dashboard section */}
        <div id="organization_dashboard" hidden>
          <div className="tracked_block block">
            <div className="tracked_icon"><TrackButton name="blue"/></div>
            <div className="tracked_title tracked">EMISSIONS<br/>TRACKED</div>
            <div className="tracked_value tracked">+ 52.1</div>
            <div className="tracked_unit tracked">tons of CO2e</div>
          </div>
          <div className="offset_block block">
            <div className="offset_icon"><OffsetButton name="green"/></div>
            <div className="offset_title offset">EMISSIONS<br/>REMOVED</div>
            <div className="offset_value offset">- 30.0</div>
            <div className="offset_unit offset">tons of CO2e</div>
          </div>
          <div className="ghg_block block">
            <div className="ghg_title tracked">GHG PROTOCOL<br/>EMISSION SCOPES</div>
            <div className="ghg_bar"><Bar data={ghg_data} options={ghg_options} /></div>
          </div>
          
          <div className="credit_block block">
            <div className="credit_title offset">BY CREDIT<br/>TYPES</div>
            <div className="pie_chart"><Doughnut data={credit_data}/></div>
            <div className="legend_block first first_offset"></div>
            <div className="legend_title first_title">FORESTRY</div>
            <div className="legend_block second second_offset"></div>
            <div className="legend_title second_title">OCEAN</div>
            <div className="legend_block third third_offset"></div>
            <div className="legend_title third_title">COMMUNITY</div>
            <div className="legend_block fourth fourth_offset"></div>
            <div className="legend_title fourth_title">RENEWABLES</div>
          </div>

          <div className="per_employee_block block">
            <div className="per_employee_title tracked">PER EMPLOYEE</div>
            <div className="per_employee_subtitle tracked">(TONS OF CO2e)</div>
            <div className="per_employee_bar">
              <Bar data={per_employee_data} options={per_employee_options} />
            </div>
          </div> 
          {/* Team block * */}
          <div className="team_block block">
            <div className="team_title">TEAM LEADERBOARD</div>
            <div className="team_icon"><WfButton name="white"/></div>
            <a href="#" className="team_tracked_button">% TRACKED  <NavigationButton name="down_blue"/></a>
            <a href="#" className="team_offset_button">% OFFSET  <NavigationButton name="down_green"/></a>
            <div className="team_employee_block team_employee_1">
              <div className="employee_name">RYM B.</div>
              <div className="employee_tracked_percentage">100%</div>
              <div className="employee_offset_percentage">100%</div>
            </div>
            <div className="team_employee_block team_employee_2">
              <div className="employee_name">ZACK T.</div>
              <div className="employee_tracked_percentage">100%</div>
              <div className="employee_offset_percentage">95%</div>
            </div>
            <div className="team_employee_block team_employee_3">
              <div className="employee_name">AHMED K.</div>
              <div className="employee_tracked_percentage">95%</div>
              <div className="employee_offset_percentage">100%</div>
            </div>
            <div className="team_employee_block team_employee_4">
              <div className="employee_name">MEHDI B.</div>
              <div className="employee_tracked_percentage">50%</div>
              <div className="employee_offset_percentage">75%</div>
            </div>
          </div> 

        </div>
      </div>
    );
  }
};




const mapStateToProps = state => ({
  employee_track_List: state.dashboard.list,
});

const mapActionToProps = {
  employeeTrack: dashboard_actions.employeeTrack,
};

Dashboard.propTypes = {
  trackValueList : PropTypes.number.isRequired,
  employeeTrack: PropTypes.func,
};

export default connect(mapStateToProps,mapActionToProps)(Dashboard);
