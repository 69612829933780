import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TrackButton extends Component {
  render() {
    const {
      name
    } = this.props;

    switch(name) {
      case 'white':
        return <img src={'/track_button.png'} />;
      case 'black':
        return <img src={'/track_black_button.png'} />;
      case 'blue':
        return <img src={'/track_blue_button.png'} />;
    }  
  }
}

TrackButton.propTypes = {
  name : PropTypes.string.isRequired
};


export default TrackButton;
