import axios from 'axios';
import baseUrl from './baseUrl';
export default {
  settings(url = baseUrl + 'settings/') {
    return {
      settingsList:()=>axios.get(url+'GetAll'),
      updateEmployees : (employee_json) => axios.post(url+'UpdateEmployee?employee_json='+employee_json),
      updateOrganizationSub : (organization_sub_json) => axios.post(url+'UpdateOrganization_Sub?organization_sub_json='+organization_sub_json),
    };
  }
};
