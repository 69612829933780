const VerifierLogin = function(email,password) {
  if(email =='')
    return {res:false,msj:'Email is required'};
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if(!re.test(String(email).toLowerCase()))
    return {res:false,msj:'Email is not valid'};
  
  if(password =='')
    return {res:false,msj:'Password is required'};
  return {res:true,msj:''};
};
export default VerifierLogin;
