import api from '../api/employees';
export const ACTION_TYPES={
  LOGIN : 'LOGIN',
  REGISTER : 'REGISTER',
  USERCONNECTED : 'USERCONNECTED'
};

export const login = (email, password, onSuccess,onFail) => dispatch => {
  api.employees().login(email, password)
    .then(res => {
      if(res.data.success)
      {
        onSuccess();
      }
      else
      {
        onFail();
      }
      dispatch({
        type: ACTION_TYPES.LOGIN,
        payload: res.data.success
      });
    })
    .catch(err => console.log(err));
};

export const register = (firstname , lastname ,email, password, onSuccess,onFail) => dispatch => {
  api.employees().register(firstname,lastname ,email, password)
    .then(res => {
      if(res.data.success)
      {
        onSuccess(res.data.message);
      }
      else
      {
        onFail(res.data.message);
      }
      dispatch({
        type: ACTION_TYPES.REGISTER,
        payload: res.data.success
      });
    })
    .catch(err => console.log(err));
};

export const userConnected = () => dispatch => {
  api.employees().userConnected()
    .then(res => {
      dispatch({
        type: ACTION_TYPES.USERCONNECTED,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};
